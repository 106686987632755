import StandardProfile from "../../../domain/modules/standardProfile/entity/StandardProfile";
import { UPDATE_STANDARD_PROFILE } from "../../redux/standardProfileList/StandardProfileListActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import StandardProfileErrors from "./StandardProfileErrors";

export default class EditStandardProfilePresenter extends ReduxPresenter implements IBasicOutput<StandardProfile> {
    constructor(dispatch: IReduxDispatch) {
        super(dispatch, new StandardProfileErrors().processor);
    }

    displaySuccessResponse(profile: StandardProfile) {
        const { id, name, description, siotRemoteSource } = profile

        this.dispatch({
            type: UPDATE_STANDARD_PROFILE,
            payload: {
                standardProfile: {
                    id: id.getId(),
                    name: name.value,
                    description,
                    siotRemoteSource: siotRemoteSource.getId()
                }
            }
        })
    }
}