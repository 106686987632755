import UtilityLogo from "../../../../domain/modules/utility/valueObject/UtilityLogo";
import IMapper from "../../../../infrastructure/IMapper";
import { UtilityLogoViewModel } from "../viewmodel/UtilityLogoViewModel";

export default class UtilityLogoPresentation implements IMapper<UtilityLogo, UtilityLogoViewModel> {

    map(input: UtilityLogo): UtilityLogoViewModel {
        return {
            url: input.url
        }
    }

}