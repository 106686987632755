import IUtilityDataSource, { UtilityData, UtilityEditData, UtilityLogoData } from "./IUtilityDataSource";
import NetworkController from "../../network/NetworkController";

export class UtilityDataSourceError extends Error {
    constructor(message: string) {
        super(`[UtilityDataSource] Error - ${message}`);
    }
}

export default class UtilityDataSource implements IUtilityDataSource {

    constructor(private nwc: NetworkController) { }

    async getUtilityList(): Promise<UtilityData[]> {
        try {
            const utilities: any = await this.nwc.request({
                url: "/admin/utilities",
                method: "GET",
                useToken: true
            });

            return utilities.data;
        }
        catch (error) {
            throw new UtilityDataSourceError(`[getUtilityList] - ${error.message}`);
        }
    }

    async getUtility(id: number): Promise<UtilityData> {
        try {
            const utility: any = await this.nwc.request({
                url: `/admin/utilities/${id}`,
                method: "GET",
                useToken: true
            });

            return utility.data;
        }
        catch (error) {
            throw new UtilityDataSourceError(`[getUtility] - ${error.message}`);
        }
    }

    async editUtility(id: number, utility: UtilityEditData): Promise<void> {
        try {
            const data = UtilityDataSource.getData(utility);

            await this.nwc.request({
                url: `/admin/utilities/${id}`,
                method: "PUT",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                useToken: true,
                data
            });
        } catch (error) {
            throw new UtilityDataSourceError(`[editUtility] - ${error.message}`);
        }
    }

    async getUtilityLogos(): Promise<UtilityLogoData[]> {
        try {
            const logos: any = await this.nwc.request({
                url: `/admin/media/logos`,
                method: "GET",
                useToken: true
            });

            return logos.data;
        } catch (error) {
            throw new UtilityDataSourceError(`[getUtilityLogos] - ${error.message}`);
        }
    }

    async uploadLogo(logo: File): Promise<UtilityLogoData> {
        try {
            const data = new FormData();
            data.append("image", logo);

            const response: any = await this.nwc.request({
                url: `/admin/media/logos`,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                useToken: true,
                data
            });

            return response.data;
        } catch (error) {
            throw new UtilityDataSourceError(`[utilityUploadLogo] - ${error.message}`);
        }
    }

    getUtilitiesMock(count: number): UtilityData[] {
        const data = [];
        for (let i: number = 1; i <= count; i++) {
            data.push({
                id: i,
                name: `utility ${i}`,
                shortName: `util ${i}`,
                description: 'this utility is generated by mock function used for test purposes',
                logoLight: 'light',
                logoDark: 'dark',
                address: 'Zürichstrasse 126 Augio, Switzerland 6547',
                email: 'john.thomas@gmail.com',
                phone: '0919342455',
                website: 'www.google.ch',
                facebook: 'http://fb.com',
                twitter: 'http://twitter.com',
                instagram: 'http://instagram.com',
                linkedIn: 'http://linkedIn.com',
                youTube: 'http://youtube.com'
            });
        }

        return data;
    }

    private static getData(utility: UtilityEditData): URLSearchParams {
        const data = new URLSearchParams();

        if (utility.name !== undefined) {
            data.append("name", utility.name);
        }
        if (utility.description !== undefined) {
            data.append("description", utility.description);
        }
        if (utility.logoLight !== undefined) {
            data.append("logoLight", utility.logoLight);
        }
        if (utility.logoDark !== undefined) {
            data.append("logoDark", utility.logoDark);
        }
        if (utility.address !== undefined) {
            data.append("address", utility.address);
        }
        if (utility.email !== undefined) {
            data.append("email", utility.email);
        }
        if (utility.phone !== undefined) {
            data.append("phone", utility.phone);
        }
        if (utility.website !== undefined) {
            data.append("website", utility.website);
        }
        if (utility.facebook !== undefined) {
            data.append("facebook", utility.facebook);
        }
        if (utility.twitter !== undefined) {
            data.append("twitter", utility.twitter);
        }
        if (utility.instagram !== undefined) {
            data.append("instagram", utility.instagram);
        }
        if (utility.linkedIn !== undefined) {
            data.append("linkedIn", utility.linkedIn);
        }
        if (utility.youTube !== undefined) {
            data.append("youTube", utility.youTube);
        }

        return data;
    }
}