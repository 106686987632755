export type TestMappingDataFactoryInput = {
    id?: number;
    buildingType?: string;
    roomsQuantity?: number;
    tumbleDrier?: boolean;
    electricityWaterHeater?: boolean;
    electricityHeater?: boolean;
    heaterType?: string;
    standardProfileId?: number;
}

export default class TestMappingDataFactory {
    public static createMapping(data: TestMappingDataFactoryInput) {
        return {
            id: this.merge(1, data.id),
            buildingType: this.merge("house", data.buildingType),
            roomsQuantity: this.merge(2, data.roomsQuantity),
            tumbleDrier: this.merge(true, data.tumbleDrier),
            electricityWaterHeater: this.merge(true, data.electricityWaterHeater),
            electricityHeater: this.merge(true, data.electricityHeater),
            heaterType: this.merge("resistance", data.heaterType),
            standardProfileId: this.merge(1, data.standardProfileId)
        }
    }

    private static merge<T>(defaultValue: T, value?: T): T {
        return value === undefined ? defaultValue : value
    }
}