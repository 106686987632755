import User from "../entity/User";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class UserListError extends Error {
    constructor(message: string) {
        super(`[UserList] Error - ${message}`)
    }
}

interface UserListProps {
    users: User[]
}

export default class UserList extends ValueObject<UserListProps> {
    get users(): User[] {
        return this.props.users
    }

    private constructor(props: UserListProps) {
        super(props)
    }

    public static create(users: User[]): UserList {
        if (!users)
            throw new UserListError('User list must not be undefined')        
        return new UserList({ users })
    }

    public equals(vo: ValueObject<UserListProps>): boolean {

        if (this.props.users.length !== vo.props.users.length)
            return false

        let result = this.props.users.filter(u1 => vo.props.users.some(u2 => u1.id.isEqual(u2.id)));
       
        return result.length === this.props.users.length
    }
}