import LocalStorage from "../../LocalStorage";
import NetworkController from "../../network/NetworkController";
import ITokenDataSource, { TokenData } from "./ITokenDataSource";

export class TokenDataSourceError extends Error {
    constructor(message: string) {
        super(`[TokenDataSource] Error - ${message}`);
    }
}

export default class TokenDataSource implements ITokenDataSource {

    constructor(private nwc: NetworkController, private storage: LocalStorage) { }

    async getToken(email: string, password: string): Promise<TokenData> {
        try {
            const data = new URLSearchParams()
            data.append("username", email);
            data.append("password", password);
            data.append("grant_type", "password");
            data.append("client_id", "android");

            const result: any = await this.nwc.request({
                url: "/admin/signin",
                method: "POST",
                data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            return {
                accessToken: result.data.access_token,
                refreshToken: result.data.refresh_token
            };
        }
        catch (error) {
            throw new TokenDataSourceError(`[getToken] - ${error.message}`)
        }
    }

    storeToken({ accessToken, refreshToken }: TokenData): void {
        this.storage.set("accessToken", accessToken);
        this.storage.set("refreshToken", refreshToken);
    }

    hasToken(): boolean {
        return this.storage.has("accessToken") && this.storage.has("refreshToken");
    }

    clearToken(): void {
        this.storage.remove("accessToken")
        this.storage.remove("refreshToken")
    }

    async checkToken(): Promise<void> {
        await this.nwc.request({
            url: "/api/v1/ping/protected",
            method: "GET",
            useToken: true
        })
    }

    getStoredToken(): TokenData {
        return {
            accessToken: this.storage.get("accessToken"),
            refreshToken: this.storage.get("refreshToken")
        }
    }

    async signOut(token: TokenData): Promise<void> {
        try {
            const data = new URLSearchParams()
            data.append("client_id", "android");
            data.append("refresh_token", token.refreshToken)

            await this.nwc.request({
                url: "/admin/logout",
                method: "POST",
                useToken: true,
                data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
        }
        catch (error) {
            throw new TokenDataSourceError(`[signOut] - ${error.message}`)
        }
    }
}