import NumericId from "../../../domain/base/valueObject/uniqueEntityID/NumericId";
import Mapping from "../../../domain/modules/mapping/entity/Mapping";
import { MappingInputs } from "../../../domain/modules/mapping/useCase/processor/AddMappingInputProcessor";
import MappingList from "../../../domain/modules/mapping/valueObject/MappingList";
import IMappingDataSource from "../../dataSource/mappingDataSource/IMappingDataSource";
import IMappingMapperFactory from "./factory/IMappingMapperFactory";
import IMappingRepository from "./IMappingRepository";

export class MappingRepositoryError extends Error {
    constructor(message: string) {
        super(`[MappingRepository] Error - ${message}`)
    }
}

export default class MappingRepository implements IMappingRepository {
    constructor(
        private _dataSource: IMappingDataSource,
        private _mapperFactory: IMappingMapperFactory
    ) { }

    async getMappings(): Promise<MappingList> {
        try {
            const mappings = await this._dataSource.getMappings()
            const mappingsMap = mappings.map(mappingData => this._mapperFactory.getMappingMapper().map(mappingData))
            return MappingList.create(mappingsMap)
        } catch (e) {
            throw new MappingRepositoryError(`[getMappings] - ${e.message}`)
        }
    }

    async editMapping(id: NumericId, mapping: Mapping): Promise<Mapping> {
        try {
            await this._dataSource.editMapping(id.getId(), this._mapperFactory.getMappingDataMapper().map(mapping));
            return mapping
        } catch (e) {
            throw new MappingRepositoryError(`[editMapping] - ${e.message}`)
        }
    }

    findMappingById(id: NumericId, mappingList: MappingList): Mapping {
        try {
            return mappingList.mappings.filter(m => m.id.equals(id))[0]
        } catch (e) {
            throw new MappingRepositoryError(`[findMappingById] - ${e.message}`)
        }
    }

    async addMapping(mapping: MappingInputs): Promise<Mapping> {
        try {
            const result = await this._dataSource.addMapping(this._mapperFactory.getMappingInputMapper().map(mapping))
            return this._mapperFactory.getMappingMapper().map(result)
        } catch (e) {
            throw new MappingRepositoryError(`[addMapping] - ${e.message}`)
        }
    }

    async deleteMapping(id: NumericId): Promise<NumericId> {
        try {
            await this._dataSource.deleteMapping(id.getId())
            return id
        } catch (e) {
            throw new MappingRepositoryError(`[deleteMapping] - ${e.message}`)
        }
    }

    async recalculateStandardProfiles(): Promise<void> {
        try {
            await this._dataSource.recalculateStandardProfiles();
        } catch (e) {
            throw new MappingRepositoryError(`[recalculateStandardProfiles] - ${e.message}`)
        }
    }
}