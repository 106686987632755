import Name from '../../../../domain/modules/user/valueObject/Name';
import Email from '../../../../domain/modules/user/valueObject/Email';
import Language from '../../../../domain/modules/user/valueObject/Language';
import ZipCode from '../../../../domain/modules/user//valueObject/ZipCode';
import StringId from '../../../../domain/base/valueObject/uniqueEntityID/StringId';
import UserFacts from '../../../../domain/modules/user//valueObject/UserFacts';

import User from "../../../../domain/modules/user//entity/User";
import { UserData } from "../../../dataSource/userDataSource/IUserDataSource";
import IMapper from "../../../IMapper";
import UserPrivacyInfo from '../../../../domain/modules/user/valueObject/UserPrivacyInfo';
import UserNotificationInfo from '../../../../domain/modules/user/valueObject/UserNotificationInfo';
import UserAcceptedTerms from '../../../../domain/modules/user/valueObject/UserAcceptedTerms';


export default class UserMapper implements IMapper<UserData, User> {
    map(input: UserData): User {
        return new User({
            name: Name.create(input.firstName, input.lastName),
            email: Email.create(input.email),
            language: Language.create(input.language),
            zipCode: ZipCode.create(input.zipCode),
            userFacts: UserFacts.create(input.hasBlinker),
            utilityId: StringId.create(input.utilityId),
            acceptedTerms: UserAcceptedTerms.create(
                input.sharedData,
                input.termsOfConditions
            ),
            notificationInfo: UserNotificationInfo.create(
                input.notificationUtility,
                input.notificationTips,
                input.notificationProgress
            ),
            privacyInfo: UserPrivacyInfo.create(
                input.privacyCrashlytics,
                input.privacyPerformance,
                input.privacyPromotion
            )
        }, StringId.create(input.id))
    }
}