import UtilityLogo from "../../../domain/modules/utility/valueObject/UtilityLogo";
import { UTILITY_GET_LOGOS_SUCCESS } from "../../redux/utility/UtilityActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import UtilityLogoPresentation from "./presentation/UtilityLogoPresentation";
import UtilityErrors from "./UtilityErrors";

export default class GetUtilityLogosPresenter extends ReduxPresenter implements IBasicOutput<UtilityLogo[]> {

    constructor(dispatch: IReduxDispatch, private _presentation: UtilityLogoPresentation) {
        super(dispatch, new UtilityErrors().processor);
    }

    displaySuccessResponse(logos: UtilityLogo[]): void {
        const logosMapped = logos.map(logo => this._presentation.map(logo));

        this.dispatch({
            type: UTILITY_GET_LOGOS_SUCCESS,
            payload: {
                logos: logosMapped
            }
        });
    }

}