import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import IDeleteMappingGateway from "../gateway/IDeleteMappingGateway";

export class DeleteMappingInteractorError extends Error {
    constructor(message: string) {
        super(`[DeleteMapping] Error - ${message}`)
    }
}

export interface IDeleteMappingInput {
    deleteMapping(id: number): Promise<void>;
}

export class DeleteMappingInteractor implements IDeleteMappingInput {
    constructor(
        private _output: IBasicOutput<NumericId>,
        private _gateway: IDeleteMappingGateway
    ) { }

    public async deleteMapping(id: number) {
        try {
            await this.interact(NumericId.create(id))
        } catch (err) {
            this._output.displayErrorResponse(new DeleteMappingInteractorError(err.message))
        }
    }

    private async interact(id: NumericId) {
        this._output.displaySuccessResponse(await this._gateway.deleteMapping(id))
    }
}