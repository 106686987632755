import Token from "../../../../domain/modules/auth/valueObject/Token";
import { TokenData } from "../../../dataSource/tokenDataSource/ITokenDataSource";
import IMapper from "../../../IMapper";
import TokenDataMapper from "../mapper/TokenDataMapper";
import TokenMapper from "../mapper/TokenMapper";
import ITokenRepositoryMapperFactory from "./ITokenRepositoryMapperFactory";

export default class TokenRepositoryMapperFactory implements ITokenRepositoryMapperFactory {
    getTokenMapper(): IMapper<TokenData, Token> {
        return new TokenMapper();
    }
    
    getTokenDataMapper(): IMapper<Token, TokenData> {
        return new TokenDataMapper();
    }
}