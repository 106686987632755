import { BASE_SNACK_OPEN } from "../../redux/base/BaseActionTypes";
import { EDIT_USER_SUCCESS } from "../../redux/user/UserActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import UserPresentation from "./presentation/UserPresentation";
import UserErrors from "./UserErrors";

export default class EditUserPresenter extends ReduxPresenter implements IBasicOutput<void> {

    constructor(dispatch: IReduxDispatch, private presentation: UserPresentation) {
        super(dispatch, new UserErrors().processor);
    }

    displaySuccessResponse(): void {
        this.dispatch({
            type: EDIT_USER_SUCCESS
        })

        this.dispatch({
            type: BASE_SNACK_OPEN,
            payload: {
                snackText: "User updated successfully!"
            }
        })
    }

}