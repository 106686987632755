import { UserViewModel } from "../../presenter/user/viewModel/UserViewModel";

export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

interface GetUsersLoading {
    type: typeof GET_USERS_LOADING,
}

interface GetUsersFail {
    type: typeof GET_USERS_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface GetUsersSuccess {
    type: typeof GET_USERS_SUCCESS,
    payload: {
        userList: UserViewModel[]
    };
}

export type UserListActionTypes = GetUsersLoading | GetUsersFail | GetUsersSuccess;