import { UserActionTypes, EDIT_USER_INPUTS, EDIT_USER_FAIL, EDIT_USER_RESET, EDIT_USER_SUCCESS, GET_USER_FAIL, GET_USER_SUCCESS, USER_LOADING, EDIT_USER_UTILITY } from "./UserActionTypes";

interface UserState {
    id: string;
    name: string;
    email: string;
    language: string;
    zipCode: string;
    hasBlinker: boolean;
    utilityId: string;
    sharedData: boolean;
    termsOfConditions: boolean;
    notificationUtility: boolean;
    notificationTips: boolean;
    notificationProgress: boolean;
    privacyCrashlytics: boolean;
    privacyPerformance: boolean;
    privacyPromotion: boolean;
    loading: boolean;
    error: string;
    success: boolean;
}

const defaultState: UserState = {
    id: "",
    name: "",
    email: "",
    language: "",
    zipCode: "",
    hasBlinker: false,
    utilityId: "",
    sharedData: false,
    termsOfConditions: false,
    notificationUtility: false,
    notificationTips: false,
    notificationProgress: false,
    privacyCrashlytics: false,
    privacyPerformance: false,
    privacyPromotion: false,
    error: "",
    loading: false,
    success: false,
}

const userReducer = (state: UserState = defaultState, action: UserActionTypes): UserState => {
    switch (action.type) {
        case GET_USER_FAIL:
            return { ...state, error: action.payload.errorMessage, loading: false }
        case USER_LOADING:
            return { ...state, loading: true }
        case GET_USER_SUCCESS:
            let user = action.payload.user
            return {
                ...defaultState,
                id: user.id,
                name: user.name,
                email: user.email,
                language: user.language,
                zipCode: user.zipCode,
                hasBlinker: user.hasBlinker,
                sharedData: user.sharedData,
                utilityId: user.utilityId,
                termsOfConditions: user.termsOfConditions,
                notificationUtility: user.notificationUtility,
                notificationTips: user.notificationTips,
                notificationProgress: user.notificationProgress,
                privacyCrashlytics: user.privacyCrashlytics,
                privacyPerformance: user.privacyPerformance,
                privacyPromotion: user.privacyPromotion,
                loading: false
            }
        case EDIT_USER_RESET:
            return { ...defaultState }
        case EDIT_USER_INPUTS:
            return { ...state, [action.payload.field]: action.payload.value }
        case EDIT_USER_SUCCESS:
            return { ...state, success: true }
        case EDIT_USER_FAIL:
            return { ...state, error: action.payload.errorMessage, loading: false }
        case EDIT_USER_UTILITY:
            return {...state, utilityId: action.payload.utilityId}
        default:
            return state;
    }
}

export default userReducer;