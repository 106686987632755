import { Container, makeStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

interface ErrorMessageProps {
    message: string | undefined;
}

const useStyles = makeStyles(() => ({
    container: {
        display:"flex",
        justifyContent: "center",
    },
    errorMessage: {
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "10px"
    }
  }))

export default function ErrorMessage({message}: ErrorMessageProps) {

    const classes = useStyles()
    
    return (
        <Container component="main" className={classes.container}>
          <Alert className={classes.errorMessage} severity="error">{message}</Alert>
        </Container>
    )
  }
  