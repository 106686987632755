import { StandardProfileData } from "../../../../../infrastructure/dataSource/standardProfileDataSource/IStandardProfileDataSource";

export type TestStandardProfileDataInput = {
    id?: number;
    name?: string;
    description?: string;
    siotRemoteSource?: string;
}

export default class TestStandardProfileDataFactory {
    public static createStandardProfileData(data: TestStandardProfileDataInput): StandardProfileData {
        return {
            id: this.merge(1, data.id),
            name: this.merge('h0', data.name),
            description: this.merge('some description', data.description),
            siotRemoteSource: this.merge('12s8fs-a1swd5-pou666', data.siotRemoteSource)
        }
    }

    private static merge<T>(defaultValue: T, value?: T): T {
        return value === undefined ? defaultValue : value
    }
}