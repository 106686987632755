import { ValueObject } from "../../../base/valueObject/ValueObject";

interface UserFactsProps {
    hasBlinker: boolean
}

export default class UserFacts extends ValueObject<UserFactsProps> {

    get hasBlinker(): boolean{
        return this.props.hasBlinker
    }

    private constructor(props: UserFactsProps) {
        super(props);
    }

    public static create(hasBlinker: boolean): UserFacts {
        return new UserFacts({ hasBlinker: this.validate(hasBlinker) });
    }

    private static validate(value?: boolean): boolean {
        if (value === undefined || value === null)
            return false;
        return value;
    }

    public equals(vo: ValueObject<UserFactsProps>): boolean {
        return this.hasBlinker === vo.props.hasBlinker;
    }
}