import User from "../../../../domain/modules/user/entity/User";
import { UserData } from "../../../dataSource/userDataSource/IUserDataSource";
import IMapper from "../../../IMapper";
import UserDataMapper from "../mapper/UserDataMapper";
import UserMapper from "../mapper/UserMapper";
import IUserRepositoryMapperFactory from "./IUserRepositoryMapperFactory";

export default class UserRepositoryMapperFactory implements IUserRepositoryMapperFactory {
    getUserMapper(): IMapper<UserData, User> {
        return new UserMapper();
    }
    getUserDataMapper(): IMapper<User, UserData>{
        return new UserDataMapper();
    }
}