import { ValueObject } from "../../../base/valueObject/ValueObject";

interface UserPrivacyInfoProps {
    crashlytics?: boolean;
    performance?: boolean;
    promotion?: boolean;
}

export default class UserPrivacyInfo extends ValueObject<UserPrivacyInfoProps> {

    get crashlytics(): boolean | undefined {
        return this.props.crashlytics;
    }

    get performance(): boolean | undefined {
        return this.props.performance;
    }

    get promotion(): boolean | undefined {
        return this.props.promotion;
    }

    private constructor(props: UserPrivacyInfoProps) {
        super(props);
    }

    public static create(crashlytics: boolean | undefined, performance: boolean | undefined, promotion: boolean | undefined): UserPrivacyInfo {
        return new UserPrivacyInfo({ crashlytics, performance, promotion });
    }

    public equals(vo: ValueObject<UserPrivacyInfoProps>): boolean {
        return vo.props.crashlytics === this.props.crashlytics &&
            vo.props.performance === this.performance &&
            vo.props.promotion === this.promotion;
    }
}