import NotEmptyString from "../../../base/valueObject/NotEmptyString"
import ISignInGateway from "../gateway/ISignInGateway"

export class SignInError extends Error {
    constructor(message: string) {
        super(`[SignIn] Error - ${message}`)
    }
}

export type SignInInputData = {
    email: string;
    password: string;
}

export interface ISignInInput {
    signIn(input: SignInInputData): Promise<void>;
}

export default interface ISignInOutput {
    displaySuccessResponse(): void;
    displayErrorResponse(error: Error): void;
}

export class SignInInteractor implements ISignInInput {

    constructor(
        private _output: ISignInOutput,
        private _gateway: ISignInGateway
    ) { }

    async signIn({ email, password }: SignInInputData): Promise<void> {
        try {
            await this.interact(NotEmptyString.create(email), NotEmptyString.create(password))
        }
        catch (err) {
            this._output.displayErrorResponse(new SignInError(err.message))
        }
    }

    private async interact(email: NotEmptyString, password: NotEmptyString) {
        this._gateway.storeToken(await this._gateway.getToken(email, password));
        this._output.displaySuccessResponse();
    }
}