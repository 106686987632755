import { Link, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

interface BreadcrumbItemProps {
  text: string;
  url: string;
  isLast: boolean;
}

const useStyles = makeStyles(() => ({
  cursor: {
    cursor: "default",
  },
}))

export default function BreadcrumbItem({
  text,
  url,
  isLast,
}: BreadcrumbItemProps) {
  const name = `${text[0].toUpperCase()}${text.substr(1, text.length)}`;
  const history = useHistory();
  const classes = useStyles();

  const lastBreadcrumb = (
    <Typography color="textPrimary" className={classes.cursor}>
      {name.toLocaleLowerCase()}
    </Typography>
  )

  const linkBreadCrumb = (
    <Link color="inherit" href="/" onClick={handleClick} >
      {name}
    </Link>
  )

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    history.push(url);
  }

  if (isLast) return lastBreadcrumb
  else return linkBreadCrumb

}
