import NotEmptyString from "../../../domain/base/valueObject/NotEmptyString";
import Mapping from "../../../domain/modules/mapping/entity/Mapping";
import { IMappingOutput } from "../../../domain/modules/mapping/useCase/EditMappingUseCase";
import { UPDATE_MAPPING } from "../../redux/mappingList/MappingListActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import MappingErrors from "./MappingErrors";

export default class EditMappingPresenter extends ReduxPresenter implements IMappingOutput {
    constructor(dispatch: IReduxDispatch) {
        super(dispatch, new MappingErrors().processor);
    }

    displaySuccessResponse(mapping: Mapping, standardProfileName: NotEmptyString): void {
        const { id, buildingType, roomsQuantity, tumbleDrier, electricityWaterHeater, electricityHeater, heaterType } = mapping;

        this.dispatch({
            type: UPDATE_MAPPING,
            payload: {
                mapping: {
                    id: id.getId(),
                    buildingType: buildingType.value,
                    roomsQuantity,
                    tumbleDrier,
                    electricityWaterHeater,
                    electricityHeater,
                    heaterType: heaterType?.value,
                    standardProfileName: standardProfileName.value
                }
            }
        })
    }
}