import { IGetStandardProfilesOutput } from "../../../domain/modules/standardProfile/useCase/GetStandardProfilesUseCase";
import StandardProfileList from "../../../domain/modules/standardProfile/valueObject/StandardProfileList";
import { GET_STANDARD_PROFILES_SUCCESS } from "../../redux/standardProfileList/StandardProfileListActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import StandardProfilePresentation from "./presentation/StandardProfilePresentation";
import StandardProfileErrors from "./StandardProfileErrors";

export default class GetStandardProfilesPresenter extends ReduxPresenter implements IGetStandardProfilesOutput {

    constructor(dispatch: IReduxDispatch, private presentation: StandardProfilePresentation) {
        super(dispatch, new StandardProfileErrors().processor);
    }

    displaySuccessResponse = (profilesList: StandardProfileList): void => {
        const profileViewModelList = this.presentation.present(profilesList)

        this.dispatch({
            type: GET_STANDARD_PROFILES_SUCCESS,
            payload: { profilesList: profileViewModelList }
        })
    }
}