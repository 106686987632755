import { ValueObject } from "../../../base/valueObject/ValueObject";

export class UserAcceptedTermsError extends Error {
    constructor(message: string) {
        super(`[UserAcceptedTerms] Error - ${message}`);
    }
}

interface UserAcceptedTermsProps {
    sharedData: boolean;
    termsOfConditions: boolean;
}

export default class UserAcceptedTerms extends ValueObject<UserAcceptedTermsProps> {

    get sharedData(): boolean {
        return this.props.sharedData;
    }

    get termsOfConditions(): boolean {
        return this.props.termsOfConditions;
    }

    private constructor(props: UserAcceptedTermsProps) {
        super(props);
    }

    public static create(sharedData: boolean, termsOfConditions: boolean): UserAcceptedTerms {
        if (!UserAcceptedTerms.isAccepted(termsOfConditions))
            throw new UserAcceptedTermsError(`"Terms of condition" is not accepted`)
        return new UserAcceptedTerms({ sharedData, termsOfConditions })
    }

    private static isAccepted(value: boolean): boolean {
        return value === true;
    }

    public equals(vo: ValueObject<UserAcceptedTermsProps>): boolean {
        return vo.props.sharedData === this.sharedData && vo.props.termsOfConditions === this.termsOfConditions;
    }
}