export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS"
export const SIGN_OUT_FAIL = "SIGN_OUT_FAIL"

interface SignOutSuccess {
    type: typeof SIGN_OUT_SUCCESS
}

interface SignOutFail {
    type: typeof SIGN_OUT_FAIL,
    payload: {
        errorMessage: string;
    };
}

export type SignOutActionTypes = SignOutSuccess | SignOutFail