import { ValueObject } from "../../../base/valueObject/ValueObject";

export class DescriptionError extends Error {
    constructor(message: string) {
        super(`[Description] Error - ${message}`);
    }
}

interface DescriptionProps {
    description?: string;
}

export default class Description extends ValueObject<DescriptionProps> {

    get description(): string | undefined {
        return this.props.description;
    }

    private constructor(props: DescriptionProps) {
        super(props);
    }

    public static create(description: string | undefined): Description {
        if (!Description.validate(description))
            throw new DescriptionError(`Description value ${description} field is not string.`)
        return new Description({ description })
    }

    private static validate(description: string | undefined): boolean {
        return typeof (description) === "string" || description === undefined
    }

    public equals(vo: ValueObject<DescriptionProps>): boolean {
        return vo.props.description === this.description;
    }
}