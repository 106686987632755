import Mapping from "../../../../domain/modules/mapping/entity/Mapping";
import { MappingData } from "../../../dataSource/mappingDataSource/IMappingDataSource";
import IMapper from "../../../IMapper";

export default class MappingDataMapper implements IMapper<Mapping, MappingData> {
    map(input: Mapping): MappingData {
        return {
            id: input.id.getId(),
            buildingType: input.buildingType.value,
            roomsQuantity: input.roomsQuantity,
            tumbleDrier: input.tumbleDrier,
            electricityWaterHeater: input.electricityWaterHeater,
            electricityHeater: input.electricityHeater,
            heaterType: input.heaterType?.value,
            standardProfileId: input.standardProfileId.getId()
        }
    }
}