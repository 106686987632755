import Utility from "../../../domain/modules/utility/entity/Utility";
import { GET_UTILITY_SUCCESS } from "../../redux/utility/UtilityActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import UtilityPresentation from "./presentation/UtilityPresentation";
import UtilityErrors from "./UtilityErrors";

export default class GetUtilityPresenter extends ReduxPresenter implements IBasicOutput<Utility> {

    constructor(dispatch: IReduxDispatch, private _presentation: UtilityPresentation) {
        super(dispatch, new UtilityErrors().processor);
    }

    displaySuccessResponse(util: Utility): void {
        const utility = this._presentation.map(util);

        this.dispatch({
            type: GET_UTILITY_SUCCESS,
            payload: {
                utility
            }
        });
    }

}