import { Dispatch } from "redux";
import { dependencyContainer } from "../../..";
import { EditUtilityInteractor } from "../../../domain/modules/utility/useCase/EditUtilityUseCase";
import { GetUtilityLogosInteractor } from "../../../domain/modules/utility/useCase/GetUtilityLogosUseCase";
import { GetUtilityInteractor } from "../../../domain/modules/utility/useCase/GetUtilityUseCase";
import ReduxDispatch from "../../presenter/ReduxDispatch";
import EditUtilityPresenter from "../../presenter/utility/EditUtilityPresenter";
import GetUtilityLogosPresenter from "../../presenter/utility/GetUtilityLogosPresenter";
import GetUtilityPresenter from "../../presenter/utility/GetUtilityPresenter";
import UtilityLogoPresentation from "../../presenter/utility/presentation/UtilityLogoPresentation";
import UtilityPresentation from "../../presenter/utility/presentation/UtilityPresentation";
import { RootStore } from "../Store";
import { EditUtilityInputsModel, EDIT_UTILITY_INPUT, EDIT_UTILITY_LOADING, EDIT_UTILITY_RESET, UTILITY_LOGO_UPDATE, UTILITY_LOGO_SET_SELECTED, UTILITY_LOGO_FILE_UPLOAD, UTILITY_LOGO_RESET_SELECTED } from "./UtilityActionTypes"

export const EditUtilityInputs = (payload: EditUtilityInputsModel) => (dispatch: Dispatch) => {
    dispatch({
        type: EDIT_UTILITY_INPUT,
        payload: {
            input: payload
        }
    });
}

export const GetUtility = (id: number) => (dispatch: Dispatch) => {
    dispatch({
        type: EDIT_UTILITY_LOADING
    });

    new GetUtilityInteractor(new GetUtilityPresenter(new ReduxDispatch(dispatch), new UtilityPresentation()),
        dependencyContainer.dependency.gatewayFactory.getUtilityGateway())
        .getUtility(id);
}

export const EditUtility = () => (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: EDIT_UTILITY_LOADING
    });

    const {
        id,
        name, shortName,
        description,
        logoLight, logoDark,
        address, email, phone, website,
        facebook, twitter, instagram, linkedIn, youTube,
        file
    } = getState().utilityReducer;

    new EditUtilityInteractor(new EditUtilityPresenter(new ReduxDispatch(dispatch)),
        dependencyContainer.dependency.gatewayFactory.getEditUtilityGateway())
        .editUtility({
            id,
            name, shortName,
            description,
            logoLight, logoDark,
            address, email, phone, website,
            facebook, twitter, instagram, linkedIn, youTube
        }, file);
}

export const EditUtilityReset = () => (dispatch: Dispatch) => {
    dispatch({
        type: EDIT_UTILITY_RESET
    });
}

export const SelectLogo = (selectedLogoType: string, selectedLogoUrl: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: UTILITY_LOGO_SET_SELECTED,
        payload: {
            selectedLogoType,
            selectedLogoUrl
        }
    });
}

export const ResetSelectedLogo = () => async (dispatch: Dispatch) => {
    dispatch({
        type: UTILITY_LOGO_RESET_SELECTED
    });
}

export const UpdateLogoValue = (url: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: UTILITY_LOGO_UPDATE,
        payload: {
            url
        }
    });
}

export const SetLogoFile = (file: File) => async (dispatch: Dispatch) => {
    dispatch({
        type: UTILITY_LOGO_FILE_UPLOAD,
        payload: {
            file
        }
    });
}

export const GetLogos = () => async (dispatch: Dispatch) => {
    dispatch({
        type: EDIT_UTILITY_LOADING
    });

    new GetUtilityLogosInteractor(new GetUtilityLogosPresenter(new ReduxDispatch(dispatch), new UtilityLogoPresentation()),
        dependencyContainer.dependency.gatewayFactory.getGetUtilityLogosGateway())
        .getUtilityLogos();
}
