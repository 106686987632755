import { UtilityViewModel } from "../../presenter/utility/viewmodel/UtilityViewModel";

export const GET_UTILITY_LIST_FAIL = "GET_UTILITY_LIST_FAIL";
export const GET_UTILITY_LIST_SUCCESS = "GET_UTILITY_LIST_SUCCESS";
export const GET_UTILITIES_LOADING = "GET_UTILITIES_LOADING";

interface GetUtilityListFail {
    type: typeof GET_UTILITY_LIST_FAIL;
    payload: {
        errorMessage: string;
    };
}

interface GetUtilityListSuccess {
    type: typeof GET_UTILITY_LIST_SUCCESS;
    payload: {
        utilityList: UtilityViewModel[];
    };
}

interface GetUtilitiesLoading {
    type: typeof GET_UTILITIES_LOADING;
}

export type UtilityListActionTypes = GetUtilitiesLoading | GetUtilityListFail | GetUtilityListSuccess;