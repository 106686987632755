import { BASE_ERROR } from "../../redux/base/BaseActionTypes";
import { SIGN_IN_FAIL } from "../../redux/signIn/SignInActionTypes";
import { ErrorCodes } from "../error/ErrorCodes";
import ErrorProcessor from "../error/ErrorProcessor";

export default class SignInErrors {
    private _processor = new ErrorProcessor(
        this.format("Internal Server Error", BASE_ERROR),
        [{
            condition: (err) => err.message.includes('[getToken]'),
            value: () => this.format(ErrorCodes.INVALID_CREDENTIALS, SIGN_IN_FAIL)
        }]
    )

    get processor() {
        return this._processor;
    }

    private format(message: string, actionType: string) {
        return { message, actionType };
    }
}