import User from "../../../../domain/modules/user/entity/User";
import { UserData } from "../../../dataSource/userDataSource/IUserDataSource";
import IMapper from "../../../IMapper";

export default class UserDataMapper implements IMapper<User, UserData> {
    map(input: User): UserData {
        return {
            id: input.id.getId(),
            firstName: input.name.firstName,
            lastName: input.name.lastName,
            email: input.email.value,
            language: input.language.value,
            zipCode: input.zipCode.value,
            hasBlinker: input.userFacts.hasBlinker,
            utilityId: input.utilityId.getId(),

            sharedData: input.acceptedTerms.sharedData,
            termsOfConditions: input.acceptedTerms.sharedData,

            notificationUtility: this.merge(false, input.notificationInfo.utility),
            notificationTips: this.merge(false, input.notificationInfo.tips),
            notificationProgress: this.merge(false, input.notificationInfo.progress),

            privacyCrashlytics: this.merge(false, input.privacyInfo.crashlytics),
            privacyPerformance: this.merge(false, input.privacyInfo.performance),
            privacyPromotion: this.merge(false, input.privacyInfo.promotion)
        }
    }

    merge<T>(defaultValue: T, value?: T): T {
        return value === undefined ? defaultValue : value
    }
}