import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import Token from "../../../../domain/modules/auth/valueObject/Token";
import { TokenData } from "../../../dataSource/tokenDataSource/ITokenDataSource";
import IMapper from "../../../IMapper";

export default class TokenMapper implements IMapper<TokenData, Token> {
    map(input: TokenData): Token {
        return new Token({
            accessToken: NotEmptyString.create(input.accessToken),
            refreshToken: NotEmptyString.create(input.refreshToken)
        })
    }
}