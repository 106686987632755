import { SignOutActionTypes, SIGN_OUT_FAIL, SIGN_OUT_SUCCESS } from "./SignOutActionTypes"

interface SignOutState {
    error?: string
}

const defaultState: SignOutState = { }

const signOutReducer = (state: SignOutState = defaultState, action: SignOutActionTypes): SignOutState => {
    switch(action.type) {
        case SIGN_OUT_SUCCESS:
            return state
        case SIGN_OUT_FAIL:
            return { ...state, error: action.payload.errorMessage }
        default:
            return state
    }
}

export default signOutReducer;