import User from "../../../../domain/modules/user/entity/User";
import IMapper from "../../../../infrastructure/IMapper";
import { UserViewModel } from "../viewModel/UserViewModel";

export default class UserPresentation implements IMapper<User, UserViewModel> {
    map(user: User): UserViewModel {
        const { id, name, email, utilityId, language, zipCode, userFacts, acceptedTerms, notificationInfo, privacyInfo } = user;
        return {
            id: id.getId(),
            name: name.firstName + " " + name.lastName,
            email: email.value,
            language: language.value,
            zipCode: zipCode.value,
            hasBlinker: userFacts.hasBlinker,
            utilityId: utilityId.getId(),

            sharedData: acceptedTerms.sharedData,
            termsOfConditions: acceptedTerms.termsOfConditions,

            notificationUtility: this.merge(false, notificationInfo.utility),
            notificationTips: this.merge(false, notificationInfo.tips),
            notificationProgress: this.merge(false, notificationInfo.progress),

            privacyCrashlytics: this.merge(false, privacyInfo.crashlytics),
            privacyPerformance: this.merge(false, privacyInfo.performance),
            privacyPromotion: this.merge(false, privacyInfo.promotion)
        }
    }

    merge<T>(defaultValue: T, value?: T): T {
        return value !== undefined ? value : defaultValue
    }
}
