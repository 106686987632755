import { Dispatch } from "redux";
import { dependencyContainer } from "../../..";
import { EditStandardProfileInteractor } from "../../../domain/modules/standardProfile/useCase/EditStandardProfileUseCase";
import { GetStandardProfilesInteractor } from "../../../domain/modules/standardProfile/useCase/GetStandardProfilesUseCase";
import ReduxDispatch from "../../presenter/ReduxDispatch";
import EditStandardProfilePresenter from "../../presenter/standardProfile/EditStandardProfilePresenter";
import GetStandardProfilesPresenter from "../../presenter/standardProfile/GetStandardProfilesPresenter";
import StandardProfilePresentation from "../../presenter/standardProfile/presentation/StandardProfilePresentation";
import { StandardProfileViewModel } from "../../presenter/standardProfile/viewModel/StandardProfileViewModel";
import { RootStore } from '../Store';
import { GET_STANDARD_PROFILES_LOADING } from "./StandardProfileListActionTypes";

export const GetStandardProfilesAction = () => async (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: GET_STANDARD_PROFILES_LOADING
    })

    const gateway = dependencyContainer.dependency.gatewayFactory.getGetStandardProfilesGateway();
    new GetStandardProfilesInteractor(new GetStandardProfilesPresenter(new ReduxDispatch(dispatch), new StandardProfilePresentation()), gateway)
        .getStandardProfiles();
}

export const EditStandardProfile = (profile: StandardProfileViewModel) => async (dispatch: Dispatch, getState: () => RootStore) => {
    const { id, description } = profile;

    new EditStandardProfileInteractor(new EditStandardProfilePresenter(new ReduxDispatch(dispatch)),
    dependencyContainer.dependency.gatewayFactory.getEditStandardProfileGateway())
        .editStandardProfile(id, { description })
}