import ISignInOutput from "../../../domain/modules/auth/useCase/SignInUseCase";
import { BASE_SET_FLAGS } from "../../redux/base/BaseActionTypes";
import { SIGN_IN_SUCCESS } from "../../redux/signIn/SignInActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import SignInErrors from "./SignInErrors";

export default class SignInPresenter extends ReduxPresenter implements ISignInOutput {

    constructor(dispatch: IReduxDispatch) {
        super(dispatch, new SignInErrors().processor);
    }

    displaySuccessResponse(): void {
        this.dispatch({
            type: SIGN_IN_SUCCESS
        })
        this.dispatch({
            type: BASE_SET_FLAGS,
            payload: { ready: true, signedIn: true }
        });
    }

}