import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import Mapping, { MappingUpdatableData } from "../entity/Mapping";
import IEditMappingGateway from "../gateway/IEditMappingGateway";
import MappingFactory from "./factory/EditMappingFactory";

export class EditMappingInteractorError extends Error {
    constructor(message: string) {
        super(`[EditMappingUseCase] Error - ${message}`)
    }
}

export interface IEditMappingInput {
    editMapping(mappingId: number, mapping: MappingUpdatableData): Promise<void>;
}

export interface IMappingOutput {
    displaySuccessResponse(mapping: Mapping, profileName: NotEmptyString): void;
    displayErrorResponse(error: Error): void;
}

export class EditMappingInteractor implements IEditMappingInput {
    constructor(
        private _output: IMappingOutput,
        private _gateway: IEditMappingGateway
    ) { }

    public async editMapping(mappingId: number, mapping: MappingUpdatableData): Promise<void> {
        try {
            await this.interact(NumericId.create(mappingId), mapping)
        } catch (error) {
            this._output.displayErrorResponse(error)
        }
    }

    private async interact(mappingId: NumericId, mappingData: MappingUpdatableData) {
        const profileList = await this._gateway.getStandardProfiles();
        const profileId = this._gateway.findStandardProfileId(NotEmptyString.create(mappingData.standardProfileName), profileList)
        const mappingEntity = MappingFactory.create(mappingId, { ...mappingData, standardProfileId: profileId.getId() })

        this._output.displaySuccessResponse(
            await this._gateway.editMapping(mappingId, mappingEntity), 
            NotEmptyString.create(mappingData.standardProfileName)
        );
    }
}