import { ICheckAuthOutput } from "../../domain/modules/auth/useCase/CheckAuthUseCase";
import { BASE_SET_FLAGS } from "../redux/base/BaseActionTypes";
import ReduxPresenter from "./ReduxPresenter";

export default class CheckAuthPresenter extends ReduxPresenter implements ICheckAuthOutput {
    displaySuccessResponse(isSignedIn: boolean): void {
        this.dispatch({
            type: BASE_SET_FLAGS,
            payload: { ready: true, signedIn: isSignedIn }
        });
    }
}