import { UtilityViewModel } from "../../../../../gui/presenter/utility/viewmodel/UtilityViewModel";
import NotEmptyString from "../../../../base/valueObject/NotEmptyString";
import NumericId from "../../../../base/valueObject/uniqueEntityID/NumericId";
import Utility from "../../entity/Utility";
import Contacts from "../../valueObject/Contacts";
import Description from "../../valueObject/Description";
import Logos from "../../valueObject/Logos";
import SocialNetworks from "../../valueObject/SocialNetworks";

export class EditUtilityFactoryError extends Error {
    constructor(message: string) {
        super(`[EditUtilityFactory] Error - ${message}`);
    }
}

export default class EditUtilityFactory {
    public static create(inputData: UtilityViewModel): Utility {
        const {
            id,
            name, shortName, description,
            logoLight, logoDark,
            address, email, phone, website,
            facebook, twitter, instagram, linkedIn, youTube
        } = inputData;

        return new Utility({
            name: NotEmptyString.create(name),
            shortName: NotEmptyString.create(shortName),
            description: Description.create(EditUtilityFactory.fillData(description)),
            logos: Logos.create(
                EditUtilityFactory.fillData(logoLight),
                EditUtilityFactory.fillData(logoDark)
            ),
            contacts: Contacts.create(
                EditUtilityFactory.fillData(address),
                EditUtilityFactory.fillData(email),
                EditUtilityFactory.fillData(phone),
                EditUtilityFactory.fillData(website)
            ),
            socialNetworks: SocialNetworks.create({
                facebook: EditUtilityFactory.fillData(facebook),
                twitter: EditUtilityFactory.fillData(twitter),
                instagram: EditUtilityFactory.fillData(instagram),
                linkedIn: EditUtilityFactory.fillData(linkedIn),
                youTube: EditUtilityFactory.fillData(youTube)
            })
        }, NumericId.create(parseInt(id)));
    }

    private static fillData(data: string): string | undefined {
        return data !== "" ? data : undefined
    }
}