import ReduxPresenter from "../ReduxPresenter";
import UtilityErrors from "./UtilityErrors";
import { IReduxDispatch } from "../IReduxDispatch";
import { BASE_SNACK_OPEN } from "../../redux/base/BaseActionTypes";
import IBasicOutput from "../output/IBasicOutput";
import { EDIT_UTILITY_SUCCESS } from "../../redux/utility/UtilityActionTypes";

export default class EditUtilityPresenter extends ReduxPresenter implements IBasicOutput<void> {

    constructor(dispatch: IReduxDispatch) {
        super(dispatch, new UtilityErrors().processor);
    }

    displaySuccessResponse(): void {

        this.dispatch({
            type: EDIT_UTILITY_SUCCESS
        });

        this.dispatch({
            type: BASE_SNACK_OPEN,
            payload: {
                snackText: "Utility updated successfully!"
            }
        });

    }

}