import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import Utility from "../entity/Utility";
import IGetUtilityGateway from "../gateway/IGetUtilityGateway";

export class GetUtilityInteractorError extends Error {
    constructor(message: string) {
        super(`[GetUtilityUseCase] Error - ${message}`);
    }
}

export interface IGetUtilityInput {
    getUtility(id: number): Promise<void>;
}

export class GetUtilityInteractor implements IGetUtilityInput {

    constructor(
        private _output: IBasicOutput<Utility>,
        private _gateway: IGetUtilityGateway
    ) { }

    public async getUtility(id: number): Promise<void> {
        try {
            await this.interact(id);
        } catch (e) {
            this._output.displayErrorResponse(new GetUtilityInteractorError(e.message));
        }
    }

    private async interact(id: number) {
        return this._output.displaySuccessResponse(await this._gateway.getUtility(id));
    }

}