import Entity from "../../../base/entity/Entity";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import BuildingType from "../valueObject/BuildingType";
import HeaterType from "../valueObject/HeaterType";

export class MappingError extends Error {
    constructor(message: string) {
        super(`[Mapping] Error - ${message}`)
    }
}

export type MappingUpdatableData = {
    buildingType: string;
    roomsQuantity: number;
    tumbleDrier: boolean;
    electricityWaterHeater: boolean;
    electricityHeater: boolean;
    heaterType?: string;
    standardProfileId: number;
    standardProfileName: string;
}

interface MappingProps {
    buildingType: BuildingType;
    roomsQuantity: number;
    tumbleDrier: boolean;
    electricityWaterHeater: boolean;
    electricityHeater: boolean;
    heaterType?: HeaterType;
    standardProfileId: NumericId;
}

export default class Mapping extends Entity<MappingProps, number> {
    get id(): NumericId {
        return NumericId.create(this._id.getId());
    }

    get buildingType(): BuildingType {
        return this.props.buildingType;
    }

    get roomsQuantity(): number {
        return this.props.roomsQuantity;
    }

    get tumbleDrier(): boolean {
        return this.props.tumbleDrier;
    }

    get electricityWaterHeater(): boolean {
        return this.props.electricityWaterHeater;
    }

    get electricityHeater(): boolean {
        return this.props.electricityHeater;
    }

    get heaterType(): HeaterType | undefined {
        return this.props.heaterType;
    }

    get standardProfileId(): NumericId {
        return this.props.standardProfileId;
    }

    public update(inputData: MappingUpdatableData): Mapping {
        return new Mapping({
            buildingType: BuildingType.create(this.merge(this.buildingType.value, inputData.buildingType)),
            roomsQuantity: this.merge(this.roomsQuantity, inputData.roomsQuantity),
            tumbleDrier: this.merge(this.tumbleDrier, inputData.tumbleDrier),
            electricityWaterHeater: this.merge(this.electricityWaterHeater, inputData.electricityWaterHeater),
            electricityHeater: this.merge(this.electricityHeater, inputData.electricityHeater),
            heaterType: this.heaterType ? HeaterType.create(this.merge(this.heaterType.value, inputData.heaterType)) : undefined,
            standardProfileId: inputData.standardProfileId ? NumericId.create(inputData.standardProfileId) : this.standardProfileId
        }, this.id)
    }

    private merge<T>(defaultValue: T, value?: T): T {
        return value === undefined ? defaultValue : value
    }
}