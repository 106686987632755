import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import StandardProfile, { StandardProfileUpdatableData } from "../entity/StandardProfile";
import IEditStandardProfileGateway from "../gateway/IEditStandardProfileGateway";

export class EditStandardProfileInteractorError extends Error {
    constructor(message: string) {
        super(`[EditStandardProfileUseCase] Error - ${message}`)
    }
}

export interface IEditStandardProfileInput {
    editStandardProfile(standardProfileId: number, standardProfile: StandardProfileUpdatableData): Promise<void>;
}

export class EditStandardProfileInteractor implements IEditStandardProfileInput {
    constructor(
        private _output: IBasicOutput<StandardProfile>,
        private _gateway: IEditStandardProfileGateway
    ) { }

    public async editStandardProfile(standardProfileId: number, standardProfile: StandardProfileUpdatableData): Promise<void> {
        try {
            await this.interact(NumericId.create(standardProfileId), standardProfile)
        } catch (error) {
            this._output.displayErrorResponse(error)
        }
    }

    private async interact(standardProfileId: NumericId, standardProfile: StandardProfileUpdatableData) {
        const profiles = await this._gateway.getStandardProfiles();
        const profileEntity = this._gateway.findStandardProfileById(standardProfileId, profiles);
        profileEntity.update(standardProfile);

        this._output.displaySuccessResponse(
            await this._gateway.editStandardProfile(standardProfileId, profileEntity)
        );
    }
}