import NotEmptyString from "../../../../base/valueObject/NotEmptyString";
import NumericId from "../../../../base/valueObject/uniqueEntityID/NumericId";
import BuildingType from "../../valueObject/BuildingType";
import HeaterType from "../../valueObject/HeaterType";
import { MappingInputData } from "../AddMappingUseCase";

export class AddMappingInputProcessorError extends Error {
    constructor(message: string) {
        super(`[AddMappingInputProcessor] Error - ${message}`);
    }
}

export type MappingInputs = {
    buildingType: BuildingType;
    roomsQuantity: number;
    tumbleDrier: boolean;
    electricityWaterHeater: boolean;
    electricityHeater: boolean;
    heaterType?: HeaterType;
    standardProfileName: NotEmptyString;
    standardProfileId: NumericId;
}

export default class AddMappingInputProcessor {
    public static processData(data: MappingInputData, profileId: number): MappingInputs {
        try {
            return {
                buildingType: BuildingType.create(data.buildingType),
                roomsQuantity: parseInt(data.roomsQuantity),
                tumbleDrier: Boolean(data.tumbleDrier),
                electricityWaterHeater: Boolean(data.electricityWaterHeater),
                electricityHeater: Boolean(data.electricityHeater),
                heaterType: data.heaterType && data.heaterType !== 'undefined' ? HeaterType.create(data.heaterType) : undefined,
                standardProfileName: NotEmptyString.create(data.standardProfileName),
                standardProfileId: NumericId.create(profileId)
            }
        } catch (error) {
            throw new AddMappingInputProcessorError(error.message);
        }
    }
}