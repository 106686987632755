import { ValueObject } from "../../../base/valueObject/ValueObject";
import StandardProfile from "../entity/StandardProfile";

export class StandardProfileListError extends Error {
    constructor(message: string) {
        super(`[StandardProfileList] Error - ${message}`)
    }
}

interface StandardProfileListProps {
    profiles: StandardProfile[]
}

export default class StandardProfileList extends ValueObject<StandardProfileListProps> {
    get profiles(): StandardProfile[] {
        return this.props.profiles
    }

    private constructor(props: StandardProfileListProps) {
        super(props)
    }

    public static create(profiles: StandardProfile[]): StandardProfileList {
        if (!profiles)
            throw new StandardProfileListError('StandardProfile list must not be undefined')        
        else return new StandardProfileList({ profiles })
    }

    public equals(vo: ValueObject<StandardProfileListProps>): boolean {
        if (this.props.profiles.length !== vo.props.profiles.length)
            return false

        let result = this.props.profiles.filter(p1 => vo.props.profiles.some(p2 => p1.id.isEqual(p2.id)));       
        return result.length === this.props.profiles.length
    }
}