import TestMappingDataFactory from "../../../domain/modules/mapping/test/factory/TestMappingDataFactory";
import NetworkController from "../../network/NetworkController";
import IMappingDataSource, { MappingData, MappingInput } from "./IMappingDataSource";

export class MappingDataSourceError extends Error {
    constructor(message: string) {
        super(`[MappingDataSource] Error - ${message}`)
    }
}

export default class MappingDataSource implements IMappingDataSource {
    constructor(private nwc: NetworkController) { }

    async getMappings(): Promise<MappingData[]> {
        try {
            const mappings: any = await this.nwc.request({
                url: "/admin/standard-profile-determiners",
                method: "GET",
                useToken: true
            })

            return mappings.data
        } catch (e) {
            throw new MappingDataSourceError(`[getMappings] - ${e.message}`)
        }
    }

    async editMapping(mappingId: number, mapping: MappingData): Promise<void> {
        try {
            const data = new URLSearchParams();
            data.append("buildingType", mapping.buildingType);
            data.append("roomsQuantity", mapping.roomsQuantity.toString());
            data.append("tumbleDrier", mapping.tumbleDrier.toString());
            data.append("electricityWaterHeater", mapping.electricityWaterHeater.toString());
            data.append("electricityHeater", mapping.electricityHeater.toString());
            data.append("standardProfileId", mapping.standardProfileId.toString());
            if (mapping.electricityHeater === true && mapping.heaterType)
                data.append("heaterType", mapping.heaterType);
            else data.append("heaterType", "");

            await this.nwc.request({
                url: `/admin/standard-profile-determiners/${mappingId}`,
                method: "PUT",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                useToken: true,
                data
            })
        } catch (error) {
            throw new MappingDataSourceError(`[editMapping] - ${error.message}`)
        }
    }

    async addMapping(mapping: MappingInput): Promise<MappingData> {
        try {
            const data = new URLSearchParams();
            data.append("buildingType", mapping.buildingType);
            data.append("roomsQuantity", mapping.roomsQuantity.toString());
            data.append("tumbleDrier", mapping.tumbleDrier.toString());
            data.append("electricityWaterHeater", mapping.electricityWaterHeater.toString());
            data.append("electricityHeater", mapping.electricityHeater.toString());
            data.append("standardProfileId", mapping.standardProfileId.toString());
            if (mapping.electricityHeater === true && mapping.heaterType)
                data.append("heaterType", mapping.heaterType);
            else data.append("heaterType", "");

            const result: any = await this.nwc.request({
                url: `/admin/standard-profile-determiners`,
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                useToken: true,
                data
            })

            return result.data
        } catch (error) {
            throw new MappingDataSourceError(`[addMapping] - ${error.message}`)
        }
    }

    async deleteMapping(mappingId: number): Promise<void> {
        try {
            await this.nwc.request({
                url: `/admin/standard-profile-determiners/${mappingId}`,
                method: "DELETE",
                useToken: true
            })
        } catch (error) {
            throw new MappingDataSourceError(`[deleteMapping] - ${error.message}`)
        }
    }

    async recalculateStandardProfiles(): Promise<void> {
        try {
            await this.nwc.request({
                url: `/admin/actions/redefine-users-standard-profiles`,
                method: "PUT",
                useToken: true
            })
        } catch (error) {
            throw new MappingDataSourceError(`[deleteMapping] - ${error.message}`)
        }
    }

    getMappingsMock(count: number): MappingData[] {
        const data: MappingData[] = []

        for (let i = 1; i <= count; i++) {
            data.push(
                TestMappingDataFactory.createMapping({
                    id: 1,
                    standardProfileId: 1
                }))
        }
        return data
    }
}