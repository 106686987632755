import { ValueObject } from "../../../base/valueObject/ValueObject";

export class LogosError extends Error {
    constructor(message: string) {
        super(`[Logos] Error - ${message}`);
    }
}

interface LogosProps {
    logoLight: string | undefined;
    logoDark: string | undefined;
}

export default class Logos extends ValueObject<LogosProps> {

    get logoLight(): string | undefined {
        return this.props.logoLight;
    }

    get logoDark(): string | undefined {
        return this.props.logoDark;
    }

    private constructor(props: LogosProps) {
        super(props);
    }

    public static create(logoLight: string | undefined, logoDark: string | undefined): Logos {
        if (!Logos.validate(logoLight))
            throw new LogosError(`Logo Light ${logoLight} is not type of string.`)
        if (!Logos.validate(logoDark))
            throw new LogosError(`Logo Light ${logoDark} is not type of string.`)
        return new Logos({ logoLight, logoDark })
    }

    private static validate(value: string | undefined): boolean {
        return typeof (value) === 'string' || value === undefined
    }

    public equals(vo: ValueObject<LogosProps>): boolean {
        return vo.props.logoLight === this.logoLight && vo.props.logoDark === this.logoDark;
    }
}