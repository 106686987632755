import Mapping from "../../../../domain/modules/mapping/entity/Mapping";
import MappingList from "../../../../domain/modules/mapping/valueObject/MappingList";
import StandardProfile from "../../../../domain/modules/standardProfile/entity/StandardProfile";
import StandardProfileList from "../../../../domain/modules/standardProfile/valueObject/StandardProfileList";
import IJoiner from "../../IJoiner";
import { MappingViewModel } from "../viewModel/MappingViewModel";

export default class MappingViewModelPresentation implements IJoiner<Mapping, StandardProfile, MappingViewModel> {
    present(mappingList: MappingList, standardProfileList: StandardProfileList): MappingViewModel[] {
        let mappingViewModelList: MappingViewModel[] = [];
        const { profiles } = standardProfileList
        const { mappings } = mappingList

        mappings.forEach(mapping => profiles.forEach(profile => {
            if (mapping.standardProfileId.isEqual(profile.id)) {
                mappingViewModelList = [...mappingViewModelList, this.join(mapping, profile)]
            }
        }))
        return mappingViewModelList;
    }

    join(mapping: Mapping, standardProfile: StandardProfile): MappingViewModel {
        return {
            id: mapping.id.getId(),
            buildingType: mapping.buildingType.value,
            roomsQuantity: mapping.roomsQuantity.toString(),
            tumbleDrier: mapping.tumbleDrier.toString(),
            electricityWaterHeater: mapping.electricityWaterHeater.toString(),
            electricityHeater: mapping.electricityHeater.toString(),
            heaterType: mapping.heaterType? mapping.heaterType.value : "none",
            standardProfileId: standardProfile.id.getId().toString(),
            standardProfileName: standardProfile.name.value
        }
    }
}