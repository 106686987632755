import { Grid, makeStyles, Theme, Button, Divider, Container } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../../components/ErrorMessage";
import Loader from "../../../../components/Loader";
import LogoElement from "../../../../components/LogoElement";
import { RootStore } from "../../../../redux/Store";
import { GetLogos, UpdateLogoValue } from "../../../../redux/utility/UtilityActions";

interface UtilityFormAllPicturesProps {
  handleCloseEditLogo: () => void;
  edit: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    position: "relative",
    width: "100%",
    height: "100%"
  },
  container: {
    width: "100%",
    height: "90%",
    position: "absolute",
    padding: theme.spacing(3),
    overflowX: "hidden"
  },
  buttons: {
    width: "100%",
    height: "20%",
    position: "absolute",
    left: 0,
    top: "90%"
  },
  buttonGrid: {
    margin: theme.spacing(0, 0, 0),
  },
  submit: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    }
  }
}));

export default function UtilityFormAllPictures(props: UtilityFormAllPicturesProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { logos, loading, error } = useSelector((state: RootStore) => state.utilityReducer);
  const [updated, setUpdated] = useState(false);
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    dispatch(GetLogos());
  }, [dispatch])

  const handleSubmit = () => {
    props.edit(true);
    dispatch(UpdateLogoValue(logos[selected].url));
    setUpdated(false);
    props.handleCloseEditLogo();
  }

  return (
    <Container
      maxWidth={false}
      className={classes.root}
      disableGutters={true}
    >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={1}
        className={classes.container}
      >
        {!loading && !error ?
          logos.length > 0 ?
            logos.map((logo, i) =>
              <LogoElement
                key={i}
                logo={logo.url}
                selected={selected}
                setSelected={setSelected}
                index={i}
                updated={updated}
                setUpdated={setUpdated}
              />
            )
            : ""
          : loading ? <Loader /> : <ErrorMessage message={error} />
        }
      </Grid>

      <Container
        maxWidth={false}
        className={classes.buttons}
        disableGutters={true}
      >
        <Divider variant="middle" />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="baseline"
          alignContent="center"
          spacing={1}
          className={classes.buttonGrid}
        >
          <Grid item xs={2} md={4} />
          <Grid item xs={8} md={4}>
            <Grid
              container
            >
              <Grid item xs={5} md={5}>
                <Button
                  type="button"
                  size="large"
                  fullWidth
                  onClick={props.handleCloseEditLogo}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={2} md={2}></Grid>
              <Grid item xs={5} md={5}>
                <Button
                  className={classes.submit}
                  type="submit"
                  size="large"
                  disabled={!updated}
                  fullWidth
                  onClick={handleSubmit}
                >
                  Select
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} md={4} />
        </Grid>
      </Container>
    </Container>
  )
}