import Mapping from "../../../../domain/modules/mapping/entity/Mapping";
import { MappingInputs } from "../../../../domain/modules/mapping/useCase/processor/AddMappingInputProcessor";
import { MappingData, MappingInput } from "../../../dataSource/mappingDataSource/IMappingDataSource";
import IMapper from "../../../IMapper";
import MappingDataMapper from "../mapper/MappingDataMapper";
import MappingInputMapper from "../mapper/MappingInputMapper";
import MappingMapper from "../mapper/MappingMapper";
import IMappingMapperFactory from "./IMappingMapperFactory";

export default class MappingMapperFactory implements IMappingMapperFactory {
    getMappingDataMapper(): IMapper<Mapping, MappingData> {
        return new MappingDataMapper();
    }
    
    getMappingMapper(): IMapper<MappingData, Mapping> {
        return new MappingMapper();
    }

    getMappingInputMapper(): IMapper<MappingInputs, MappingInput> {
        return new MappingInputMapper();
    }
}