import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput"
import IGetUtilityLogosGateway from "../gateway/IGetUtilityLogosGateway"
import UtilityLogo from "../valueObject/UtilityLogo"

export class GetUtilityLogosInteractorError extends Error {
    constructor(message: string) {
        super(`[GetUtilityLogosUseCase] Error - ${message}`)
    }
}

export interface IGetUtilityLogosInput {
    getUtilityLogos(): Promise<void>;
}

export class GetUtilityLogosInteractor implements IGetUtilityLogosInput {

    constructor(
        private _output: IBasicOutput<UtilityLogo[]>,
        private _gateway: IGetUtilityLogosGateway
    ) { }

    public async getUtilityLogos(): Promise<void> {
        try {
            await this.interact()
        } catch (e) {
            this._output.displayErrorResponse(new GetUtilityLogosInteractorError(e.message))
        }
    }

    private async interact() {
        return this._output.displaySuccessResponse(await this._gateway.getUtilityLogos())
    }

}