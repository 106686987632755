import { ValueObject } from "../../../base/valueObject/ValueObject"
import Utility from "../entity/Utility"

export class UtilityListError extends Error {
    constructor(message: string) {
        super(`[UtilityList] Error - ${message}`);
    }
}

interface UtilityListProps {
    utilities: Utility[];
}

export default class UtilityList extends ValueObject<UtilityListProps> {
    get utilities(): Utility[] {
        return this.props.utilities
    }

    private constructor(props: UtilityListProps) {
        super(props);
    }

    public static create(utilities: Utility[]): UtilityList {
        if (!utilities)
            throw new UtilityListError('Utility list must not be undefined');
        return new UtilityList({ utilities })
    }

    public equals(vo: ValueObject<UtilityListProps>): boolean {
        return (this.props.utilities.length === vo.props.utilities.length)
            && this.props.utilities.every(utility => {
                return vo.props.utilities.some(secondUtility => {
                    return utility.id.isEqual(secondUtility.id);
                });
            });
    }
}