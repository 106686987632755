import { MappingViewModel } from "../../presenter/mapping/viewModel/MappingViewModel";
import { ADD_MAPPING, ADD_MAPPING_FAIL, DELETE_MAPPING, DELETE_MAPPING_FAIL, GetMappingsActionTypes, GET_MAPPINGS_FAIL, GET_MAPPINGS_LOADING, GET_MAPPINGS_SUCCESS, UPDATE_MAPPING_FAIL, UPDATE_MAPPING, RECALCULATE_STANDARD_PROFILES_FAIL } from "./MappingListActionTypes";

interface GetMappingsState {
    mappingList: MappingViewModel[];
    loading: boolean;
    error?: string;
}

const defaultState: GetMappingsState = {
    mappingList: [],
    loading: false
}

const mappingsReducer = (state: GetMappingsState = defaultState, action: GetMappingsActionTypes): GetMappingsState => {
    switch (action.type) {
        case GET_MAPPINGS_LOADING:
            return { ...state, loading: true }
        case GET_MAPPINGS_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage }
        case GET_MAPPINGS_SUCCESS:
            return { ...state, loading: false, mappingList: action.payload.mappingList }
        case UPDATE_MAPPING:
            const updatedList = state.mappingList.map(m => {
                if (m.id === action.payload.mapping.id) {
                    return action.payload.mapping
                } else return m
            })
            return { ...state, mappingList: updatedList }
        case UPDATE_MAPPING_FAIL:
            return { ...state, error: action.payload.errorMessage }
        case ADD_MAPPING:
            const newList = [...state.mappingList, action.payload.mapping]
            return { ...state, mappingList: newList }
        case ADD_MAPPING_FAIL:
            return { ...state, error: action.payload.errorMessage }
        case DELETE_MAPPING:
            const filteredList = state.mappingList.filter(m => m.id !== action.payload.id)
            return { ...state, mappingList: filteredList }
        case DELETE_MAPPING_FAIL:
            return { ...state, error: action.payload.errorMessage }
        case RECALCULATE_STANDARD_PROFILES_FAIL:
            return { ...state, error: action.payload.errorMessage }
        default:
            return state;
    }
}

export default mappingsReducer;