import StandardProfile from "../../../../domain/modules/standardProfile/entity/StandardProfile";
import StandardProfileList from "../../../../domain/modules/standardProfile/valueObject/StandardProfileList";
import IMapper from "../../../../infrastructure/IMapper";
import { StandardProfileViewModel } from "../viewModel/StandardProfileViewModel";

export default class StandardProfilePresentation implements IMapper<StandardProfile, StandardProfileViewModel> {

    present(profilesList: StandardProfileList): StandardProfileViewModel[] {
        const profileViewModelList = profilesList.profiles.map(profile => {
            return this.map(profile)
        })

        return profileViewModelList
    }

    map(input: StandardProfile): StandardProfileViewModel {
        const { id, name, description, siotRemoteSource } = input;

        return {
            id: id.getId(),
            name: name.value,
            description,
            siotRemoteSource: siotRemoteSource.getId().toString()
        }
    }
}