import { ValueObject } from "../../../base/valueObject/ValueObject";

export class SocialNetworksError extends Error {
    constructor(message: string) {
        super(`[SocialNetworks] Error - ${message}`);
    }
}

interface SocialNetworksProps {
    facebook: string | undefined;
    twitter: string | undefined;
    instagram: string | undefined;
    linkedIn: string | undefined;
    youTube: string | undefined;
}

export default class SocialNetworks extends ValueObject<SocialNetworksProps> {

    get facebook(): string | undefined {
        return this.props.facebook;
    }

    get twitter(): string | undefined {
        return this.props.twitter;
    }

    get instagram(): string | undefined {
        return this.props.instagram;
    }

    get linkedIn(): string | undefined {
        return this.props.linkedIn;
    }

    get youTube(): string | undefined {
        return this.props.youTube
    }

    private constructor(props: SocialNetworksProps) {
        super(props);
    }

    public static create(props: SocialNetworksProps): SocialNetworks {
        const { facebook, twitter, instagram, linkedIn, youTube } = props
        if (!SocialNetworks.validate(facebook))
            throw new SocialNetworksError(`Facebook ${facebook} field is not string.`)
        if (!SocialNetworks.validate(twitter))
            throw new SocialNetworksError(`Twitter ${twitter} field is not string.`)
        if (!SocialNetworks.validate(instagram))
            throw new SocialNetworksError(`Instagram ${instagram} field is not string.`)
        if (!SocialNetworks.validate(linkedIn))
            throw new SocialNetworksError(`LinkedIn ${linkedIn} field is not string.`)
        return new SocialNetworks({ facebook, twitter, instagram, linkedIn, youTube })
    }

    private static validate(value: string | undefined): boolean {
        return typeof (value) === "string" || value === undefined
    }

    public equals(vo: ValueObject<SocialNetworksProps>): boolean {
        return vo.props.facebook === this.facebook && vo.props.twitter === this.twitter
            && vo.props.instagram === this.instagram && vo.props.linkedIn === this.linkedIn;
    }
}