import { ValueObject } from "../../../base/valueObject/ValueObject";

export class HeaterTypeError extends Error {
    constructor(message: string) {
        super(`[HeaterType] Error - ${message}`)
    }
}

interface HeaterTypeProps {
    value: string;
}

export default class HeaterType extends ValueObject<HeaterTypeProps> {
    get value(): string {
        return this.props.value;
    }

    private constructor(props: HeaterTypeProps) {
        super(props);
    }

    public static create(value: string): HeaterType {
        if (!HeaterType.validate(value))
            throw new HeaterTypeError(`Provided heater type is not valid. Heater type - ${value}`)
        else
            return new HeaterType({ value });
    }

    private static validate(value: string): boolean {
        if (!value) return false
        return ["heat pump", "resistance"].indexOf(value.toLocaleLowerCase()) !== -1;
    }

    public equals(vo: ValueObject<HeaterTypeProps>): boolean {
        return vo.props.value === this.props.value
    }
}