import { Dispatch } from "redux";
import { dependencyContainer } from "../../..";
import { AddMappingInteractor } from "../../../domain/modules/mapping/useCase/AddMappingUseCase";
import { DeleteMappingInteractor } from "../../../domain/modules/mapping/useCase/DeleteMappingUseCase";
import { EditMappingInteractor } from "../../../domain/modules/mapping/useCase/EditMappingUseCase";
import { GetMappingsInteractor } from "../../../domain/modules/mapping/useCase/GetMappingsUseCase";
import GetMappingsPresenter from "../../presenter/mapping/GetMappingsPresenter";
import EditMappingPresenter from "../../presenter/mapping/EditMappingPresenter";
import MappingViewModelPresentation from "../../presenter/mapping/presentation/MappingViewModelPresentation";
import { MappingViewModel } from "../../presenter/mapping/viewModel/MappingViewModel";
import ReduxDispatch from "../../presenter/ReduxDispatch";
import { RootStore } from "../Store";
import AddMappingPresenter from "../../presenter/mapping/AddMappingPresenter";
import DeleteMappingPresenter from "../../presenter/mapping/DeleteMappingPresenter";
import { GET_MAPPINGS_LOADING } from "./MappingListActionTypes";
import { RecalculateStandardProfilesInteractor } from "../../../domain/modules/mapping/useCase/RecalculateStandardProfilesUseCase";
import RecalculateStandardProfilesPresenter from "../../presenter/mapping/RecalculateStandardProfilesPresenter";
import { BASE_SNACK_OPEN } from "../base/BaseActionTypes";

export const GetMappingsAction = () => async (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: GET_MAPPINGS_LOADING
    })

    const gateway = dependencyContainer.dependency.gatewayFactory.getGetMappingsGateway()
    new GetMappingsInteractor(new GetMappingsPresenter(new ReduxDispatch(dispatch), new MappingViewModelPresentation()), gateway)
        .getMappings();
}

export const EditMapping = (mapping: MappingViewModel) => (dispatch: Dispatch, getState: () => RootStore) => {
    const { id, buildingType, roomsQuantity, tumbleDrier, electricityWaterHeater, electricityHeater, heaterType, standardProfileName, standardProfileId } = mapping;

    new EditMappingInteractor(new EditMappingPresenter(new ReduxDispatch(dispatch)),
        dependencyContainer.dependency.gatewayFactory.getEditMappingGateway())
        .editMapping(id, {
            buildingType,
            roomsQuantity: parseInt(roomsQuantity),
            tumbleDrier: Boolean(tumbleDrier),
            electricityWaterHeater: Boolean(electricityWaterHeater),
            electricityHeater: Boolean(electricityHeater),
            heaterType,
            standardProfileId: parseInt(standardProfileId),
            standardProfileName
        })
}

export const AddMapping = (mapping: MappingViewModel) => async (dispatch: Dispatch, getState: () => RootStore) => {
    const { buildingType, roomsQuantity, tumbleDrier, electricityWaterHeater, electricityHeater, heaterType, standardProfileName } = mapping;

    new AddMappingInteractor(new AddMappingPresenter(new ReduxDispatch(dispatch)),
        dependencyContainer.dependency.gatewayFactory.getAddMappingGateway())
        .addMapping({
            buildingType,
            roomsQuantity,
            tumbleDrier,
            electricityWaterHeater,
            electricityHeater,
            heaterType,
            standardProfileName
        })
}

export const DeleteMapping = (id: number) => async (dispatch: Dispatch, getState: () => RootStore) => {
    new DeleteMappingInteractor(new DeleteMappingPresenter(new ReduxDispatch(dispatch)),
        dependencyContainer.dependency.gatewayFactory.getDeleteMappingGateway())
        .deleteMapping(id)
}

export const RecalculateStandardProfiles = () => async (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: BASE_SNACK_OPEN,
        payload: {
            snackText: "Starting recalculation..."
        }
    })

    new RecalculateStandardProfilesInteractor(new RecalculateStandardProfilesPresenter(new ReduxDispatch(dispatch)),
        dependencyContainer.dependency.gatewayFactory.getRecalculateStandardProfilesGateway())
        .recalculateStandardProfiles()
}