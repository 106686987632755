import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import IAddMappingGateway from "../gateway/IAddMappingGateway";
import { IMappingOutput } from "./EditMappingUseCase";
import AddMappingInputProcessor from "./processor/AddMappingInputProcessor";


export class AddMappingInteractorError extends Error {
    constructor(message: string) {
        super(`[AddMapping] Error - ${message}`)
    }
}

export type MappingInputData = {
    buildingType: string;
    roomsQuantity: string;
    tumbleDrier: string;
    electricityWaterHeater: string;
    electricityHeater: string;
    heaterType?: string;
    standardProfileName: string;
}

export interface IAddMappingInput {
    addMapping(input: MappingInputData): Promise<void>;
}

export class AddMappingInteractor implements IAddMappingInput {
    constructor(
        private _output: IMappingOutput,
        private _gateway: IAddMappingGateway
    ) { }

    public async addMapping(mapping: MappingInputData) {
        try {
            await this.interact(mapping)
        } catch (err) {
            this._output.displayErrorResponse(new AddMappingInteractorError(err.message))
        }
    }

    private async interact(mappingData: MappingInputData) {
        const profileList = await this._gateway.getStandardProfiles();
        const profileId = this._gateway.findStandardProfileId(NotEmptyString.create(mappingData.standardProfileName), profileList);
        const newMapping = await this._gateway.addMapping(AddMappingInputProcessor.processData(mappingData, profileId.getId()));

        this._output.displaySuccessResponse(
            newMapping,
            NotEmptyString.create(mappingData.standardProfileName)
        );
    }
}