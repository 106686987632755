import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import IGetUtilityListGateway from "../gateway/IGetUtilityListGateway";
import UtilityList from "../valueObject/UtilityList";

export class GetUtilityListInteractorError extends Error {
    constructor(message: string) {
        super(`[GetUtilityListUseCase] Error - ${message}`);
    }
}

export interface IGetUtilityListInput {
    getUtilityList(): Promise<void>;
}

export class GetUtilityListInteractor implements IGetUtilityListInput {

    constructor(
        private _output: IBasicOutput<UtilityList>,
        private _gateway: IGetUtilityListGateway
    ) { }

    public async getUtilityList(): Promise<void> {
        try {
            await this.interact();
        } catch (e) {
            this._output.displayErrorResponse(new GetUtilityListInteractorError(e.message));
        }
    }

    private async interact() {
        return this._output.displaySuccessResponse(await this._gateway.getUtilityList());
    }

}