import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import styles from "./snackbarContentStyle";

const useStyles = makeStyles(styles);

export type ColorTypes = 'info' | 'success' | 'warning' | 'danger' | 'primary' | 'rose';
export type PlaceTypes = 'tl' | 'tr' | 'tc' | 'br' | 'bl' | 'bc'

interface SnackbarProps {
  message: string;
  color: ColorTypes;
  autoHideDuration: number;
  close: boolean;
  place: PlaceTypes;
  open: boolean;
  closeNotification: () => void;
}

const customStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main
  },
  font: {
    fontWeight: "bold"
  }
}))

export default function Snackbar(props: SnackbarProps) {
  const classes = useStyles();
  const customClasses = customStyles();
  let { message, color, close, place, open, autoHideDuration } = props;
  color = color ? color : 'info'
  let action: any[] = [];
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={props.closeNotification}
      >
        <Close className={classes.close} />
      </IconButton>,
    ];
  }
  return (
    <Snack
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40,
      }}
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          place.indexOf("l") !== -1
            ? "left"
            : place.indexOf("c") !== -1
              ? "center"
              : "right",
      }}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={props.closeNotification}
      message={
        <div>
          <span>{message}</span>
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message
        },
        className: customClasses.root + " " + customClasses.font
      }}
    />
  );
}

