import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import { IRecalculateStandardProfilesGateway } from "../gateway/IRecalculateStandardProfilesGateway";

export class RecalculateStandardProfilesInteractorError extends Error {
    constructor(message: string) {
        super(`[RecalculateStandardProfiles] Error - ${message}`)
    }
}

export interface IRecalculateStandardProfilesInput {
    recalculateStandardProfiles(): Promise<void>;
}

export class RecalculateStandardProfilesInteractor implements IRecalculateStandardProfilesInput {
    constructor(
        private _output: IBasicOutput<void>,
        private _gateway: IRecalculateStandardProfilesGateway
    ) { }

    public async recalculateStandardProfiles() {
        try {
            await this.interact()
        } catch (err) {
            this._output.displayErrorResponse(new RecalculateStandardProfilesInteractorError(err.message))
        }
    }

    private async interact() {
        this._output.displaySuccessResponse(await this._gateway.recalculateStandardProfiles())
    }
}