import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId"
import { UserUpdatableData } from "../entity/User"
import IEditUserGateway from "../gateway/IEditUserGateway"


export class EditUserInteractorError extends Error {
    constructor(message: string) {
        super(`[EditUserUseCase] Error - ${message}`);
    }
}

export interface IEditUserInput {
    editUser(id: string, user: UserUpdatableData): Promise<void>;
}

export class EditUserInteractor implements IEditUserInput {
    constructor(
        private _output: IBasicOutput<void>,
        private _gateway: IEditUserGateway
    ) { }

    public async editUser(id: string, user: UserUpdatableData): Promise<void> {
        try {
            await this.interact(StringId.create(id), user);
        } catch (e) {
            this._output.displayErrorResponse(new EditUserInteractorError(e.message));
        }
    }

    private async interact(id: StringId, userUpdate: UserUpdatableData) {
        const userList = await this._gateway.getUsers();
        
        const user = this._gateway.findUserById(id, userList);
        const userEntity = user.update(userUpdate);

        await this._gateway.editUser(id, userEntity);
        this._output.displaySuccessResponse();
    }
}