import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { StandardProfileViewModel } from "../../presenter/standardProfile/viewModel/StandardProfileViewModel";

interface StandardProfileTableProps {
  profileList: StandardProfileViewModel[];
  handleEdit: (
    newData: StandardProfileViewModel,
    oldData: StandardProfileViewModel | undefined
  ) => Promise<any>;
}

export default function StandardProfileTable(props: StandardProfileTableProps) {
  const { profileList, handleEdit } = props;
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      <MaterialTable
        columns={[
          {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
            editable: "never"
          },
          {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
          },
          {
            title: "Siot Remote ID",
            field: "siotRemoteSource",
            filterPlaceholder: "Filter by Siot Remote ID",
            editable: "never"
          },
        ]}
        data={profileList}
        editable={{
          onRowUpdate: handleEdit
        }}
        options={{
          filtering: true,
          search: false,
          showTitle: false,
          pageSize: 10,
          pageSizeOptions: [10, 15, 20, 25],
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1,
        }}
      />
    </Grid>
  );
}
