import Name from '../valueObject/Name';
import Email from '../valueObject/Email';
import Language from '../valueObject/Language';
import ZipCode from '../valueObject/ZipCode';
import StringId from '../../../base/valueObject/uniqueEntityID/StringId';
import UserFacts from '../valueObject/UserFacts';
import Entity from '../../../base/entity/Entity';
import UserAcceptedTerms from '../valueObject/UserAcceptedTerms';
import UserNotificationInfo from '../valueObject/UserNotificationInfo';
import UserPrivacyInfo from '../valueObject/UserPrivacyInfo';

export class UserError extends Error {
    constructor(message: string) {
        super(`[User] Error - ${message}`);
    }
}

interface UserProps {
    name: Name;
    email: Email;
    language: Language;
    zipCode: ZipCode;
    userFacts: UserFacts;
    utilityId: StringId;

    acceptedTerms: UserAcceptedTerms;
    notificationInfo: UserNotificationInfo;
    privacyInfo: UserPrivacyInfo;
}

export type UserUpdatableData = {
    zipCode?: string;
    utilityId?: string;
}

export default class User extends Entity<UserProps, string> {
    get id(): StringId {
        return StringId.create(this._id.getId());
    }

    get name(): Name {
        return this.props.name;
    }

    get email(): Email {
        return this.props.email;
    }

    get language(): Language {
        return this.props.language;
    }

    get zipCode(): ZipCode {
        return this.props.zipCode;
    }

    get userFacts(): UserFacts {
        return this.props.userFacts;
    }

    get utilityId(): StringId {
        return this.props.utilityId;
    }

    get acceptedTerms(): UserAcceptedTerms {
        return this.props.acceptedTerms;
    }

    get privacyInfo(): UserPrivacyInfo {
        return this.props.privacyInfo;
    }

    get notificationInfo(): UserNotificationInfo {
        return this.props.notificationInfo;
    }

    public update(data: UserUpdatableData): User {
        try {
            return new User({
                name: this.name,
                email: this.email,
                zipCode: data.zipCode ? ZipCode.create(data.zipCode) : this.zipCode,
                language: this.language,
                utilityId: data.utilityId ? StringId.create(data.utilityId): this.utilityId,
                userFacts: this.userFacts,
                notificationInfo: this.notificationInfo,
                privacyInfo: this.privacyInfo,
                acceptedTerms: this.acceptedTerms,
            }, this.id)
        } catch (e) {
            throw new UserError(e.message)
        }
    }
}