import UtilityList from "../../../domain/modules/utility/valueObject/UtilityList";
import { GET_UTILITY_LIST_SUCCESS } from "../../redux/utilityList/UtilityListActionTypes";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import UtilityErrors from "./UtilityErrors";
import UtilityPresentation from "./presentation/UtilityPresentation"
import { IReduxDispatch } from "../IReduxDispatch";

export default class GetUtilityListPresenter extends ReduxPresenter implements IBasicOutput<UtilityList> {

    constructor(dispatch: IReduxDispatch, private _presentation: UtilityPresentation) {
        super(dispatch, new UtilityErrors().processor);
    }

    displaySuccessResponse(util: UtilityList): void {
        const utilityList = this._presentation.present(util);

        this.dispatch({
            type: GET_UTILITY_LIST_SUCCESS,
            payload: {
                utilityList
            }
        });
    }

}