import React from "react";
import SignInPage from "../../page/signIn";
import { Route } from "react-router-dom";

export default function NotAuthorizedRoutes() {
  return (
    <div>
      <Route path="/sign-in" component={SignInPage} />
    </div>
  );
}
