import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Card from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import { GetUsers } from "../../redux/userList/UserListActions";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../components/Loader";
import { GetUtilities } from "../../redux/utilityList/UtilityListActions";

export default function Home() {
  const usersState = useSelector((state: RootStore) => state.userListReducer);

  const utilitiesState = useSelector(
    (state: RootStore) => state.utilityListReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetUsers());
    dispatch(GetUtilities());
  }, [dispatch]);

  const errorView = (error: string) => <ErrorMessage message={error} />;
  const loadingView = <Loader />;
  const view = (
    <Grid
      container
      item
      xs={6}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Card
        label="USERS"
        value={usersState.userList.length.toString()}
        icon="people"
      />
      {/* <Card label="BLINKERS" value="1008" icon="wifi" /> */}
      <Card
        label="UTILITIES"
        value={utilitiesState.utilityList.length.toString()}
        icon="domain"
      />
    </Grid>
  );

  let viewToRender;
  if (!usersState.error && !utilitiesState.error) {
    viewToRender = view;
  } else if (usersState.loading || utilitiesState.loading) {
    viewToRender = loadingView;
  } else if (usersState.error) {
    viewToRender = errorView("Error fetching users");
  } else if (utilitiesState.error) {
    viewToRender = errorView("Error fetching utilities");
  } else viewToRender = errorView("Unknown error");

  return viewToRender;
}
