import { ValueObject } from "../../../base/valueObject/ValueObject";

export class UtilityLogoError extends Error {
    constructor(message: string) {
        super(`[UtilityLogo] Error - ${message}`);
    }
}

interface UtilityLogoProps {
    name: string;
    url: string;
}

export default class UtilityLogo extends ValueObject<UtilityLogoProps> {

    get name(): string {
        return this.props.name;
    }

    get url(): string {
        return this.props.url;
    }

    private constructor(props: UtilityLogoProps) {
        super(props);
    }

    public static create(name: string, url: string): UtilityLogo {
        if (!UtilityLogo.validate(name))
            throw new UtilityLogoError(`Logo Light ${name} is not type of string.`);
        if (!UtilityLogo.validate(url))
            throw new UtilityLogoError(`Logo Light ${url} is not type of string.`);
        return new UtilityLogo({ name, url });
    }

    private static validate(value: string): boolean {
        return typeof (value) === 'string';
    }

    public equals(vo: ValueObject<UtilityLogoProps>): boolean {
        return vo.props.name === this.name && vo.props.url === this.url;
    }
}