import StandardProfile from "../../../../domain/modules/standardProfile/entity/StandardProfile";
import { StandardProfileData } from "../../../dataSource/standardProfileDataSource/IStandardProfileDataSource";
import IMapper from "../../../IMapper";

export default class StandardProfileDataMapper implements IMapper<StandardProfile, StandardProfileData> {
    map(input: StandardProfile): StandardProfileData {
        return {
            id: input.id.getId(),
            name: input.name.value,
            description: input.description,
            siotRemoteSource: input.siotRemoteSource.getId()
        }
    }
}