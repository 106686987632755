import { BASE_ERROR } from "../../redux/base/BaseActionTypes";
import { ADD_MAPPING_FAIL, DELETE_MAPPING_FAIL, GET_MAPPINGS_FAIL, RECALCULATE_STANDARD_PROFILES_FAIL, UPDATE_MAPPING_FAIL } from "../../redux/mappingList/MappingListActionTypes";
import { ErrorCodes } from "../error/ErrorCodes";
import ErrorProcessor from "../error/ErrorProcessor";


export default class MappingsError {
    private _processor = new ErrorProcessor(
        this.format("Internal Server Error", BASE_ERROR),
        [{
            condition: (err) => err.message.includes('[getMappings]'),
            value: () => this.format(ErrorCodes.GET_MAPPINGS_FAIL, GET_MAPPINGS_FAIL)
        },
        {
            condition: (err) => err.message.includes('[editMapping]'),
            value: () => this.format(ErrorCodes.UPDATE_MAPPING_FAIL, UPDATE_MAPPING_FAIL)
        },
        {
            condition: (err) => err.message.includes('[addMapping]') || err.message.includes('[AddMapping]'),
            value: () => this.format(ErrorCodes.ADD_MAPPING_FAIL, ADD_MAPPING_FAIL)
        },
        {
            condition: (err) => err.message.includes('[deleteMapping]'),
            value: () => this.format(ErrorCodes.DELETE_MAPPING_FAIL, DELETE_MAPPING_FAIL)
        },
        {
            condition: (err) => err.message.includes('[recalculateStandardProfiles]'),
            value: () => this.format(ErrorCodes.RECALCULATE_STANDARD_PROFILES_FAIL, RECALCULATE_STANDARD_PROFILES_FAIL)
        }]
    )

    get processor() {
        return this._processor;
    }

    private format(message: string, actionType: string) {
        return { message, actionType };
    }
}