import { UserViewModel } from "../../presenter/user/viewModel/UserViewModel";

export const USER_LOADING = "USER_LOADING";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const EDIT_USER_RESET = "EDIT_USER_RESET";
export const EDIT_USER_INPUTS = "EDIT_USER_INPUTS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_UTILITY = "EDIT_USER_UTILITY";

interface UserLoading {
    type: typeof USER_LOADING,
}

interface GetUserFail {
    type: typeof GET_USER_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface GetUserSuccess {
    type: typeof GET_USER_SUCCESS,
    payload: {
        user: UserViewModel
    };
}

export type EditUserInputsModel = {
    field: string;
    value: string;
}

interface EditUserSuccess {
    type: typeof EDIT_USER_SUCCESS;
}

interface EditUserReset {
    type: typeof EDIT_USER_RESET
}

interface EditUserInputs {
    type: typeof EDIT_USER_INPUTS,
    payload: EditUserInputsModel;
}

interface EditUserFail {
    type: typeof EDIT_USER_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface EditUserUtility {
    type: typeof EDIT_USER_UTILITY,
    payload: {
        utilityId: string;
    }
}

export type UserActionTypes =
    UserLoading | GetUserFail | GetUserSuccess |
    EditUserReset | EditUserInputs | EditUserFail |
    EditUserSuccess | EditUserUtility;