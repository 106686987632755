import { GetMappingsGatewayError } from "../../../../infrastructure/repository/mappingRepository/gateway/GetMappingsGateway";
import StandardProfileList from "../../standardProfile/valueObject/StandardProfileList";
import IGetMappingsGateway from "../gateway/IGetMappingsGateway";
import MappingList from "../valueObject/MappingList";

export class GetMappingsInteractorError extends Error {
    constructor(message: string) {
        super(`[GetMappingsUseCase] Error - ${message}`)
    }
}

export interface IGetMappingsInput {
    getMappings(): Promise<void>;
}

export interface IGetMappingsOutput {
    displaySuccessResponse(mappingList: MappingList, standardProfileList: StandardProfileList): void;
    displayErrorResponse(error: Error): void;
}

export class GetMappingsInteractor implements IGetMappingsInput {
    constructor(
        private _output: IGetMappingsOutput,
        private _gateway: IGetMappingsGateway
    ) { }

    public async getMappings(): Promise<void> {
        try {
            await this.interact();
        } catch (e) {
            this._output.displayErrorResponse(new GetMappingsGatewayError(e.message))
        }
    }

    private async interact() {
        const standardProfiles = await this._gateway.getStandardProfiles();
        const mappings = await this._gateway.getMappings();

        this._output.displaySuccessResponse(mappings, standardProfiles);
    }
}