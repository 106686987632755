import Entity from "../../../base/entity/Entity";
import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";

export class StandardProfileError extends Error {
    constructor(message: string) {
        super(`[StandardProfile] Error - ${message}`)
    }
}

export type StandardProfileUpdatableData = {
    description: string;
}

interface StandardProfileProps {
    name: NotEmptyString;
    description: string;
    siotRemoteSource: StringId;
}

export default class StandardProfile extends Entity<StandardProfileProps, number> {
    get id(): NumericId {
        return NumericId.create(this._id.getId());
    }
    
    get name(): NotEmptyString {
        return this.props.name
    }

    get description(): string {
        return this.props.description
    }

    get siotRemoteSource(): StringId {
        return this.props.siotRemoteSource
    }

    set description(description: string) {
        this.props.description = description;
    }

    public update(inputData: StandardProfileUpdatableData): StandardProfile {
        this.description = inputData.description;
        return this; 
    }
}