import Utility from "../../../../domain/modules/utility/entity/Utility";
import { UtilityEditData } from "../../../dataSource/utilityDataSource/IUtilityDataSource";
import IMapper from "../../../IMapper";

export default class UtilityEditDataMapper implements IMapper<Utility, UtilityEditData> {
    map(input: Utility): UtilityEditData {
        const { description, logos, contacts, socialNetworks, name } = input
        return {
            name: name?.value,
            description: description?.description,
            logoLight: logos?.logoLight,
            logoDark: logos?.logoDark,
            address: contacts?.address,
            email: contacts?.email,
            phone: contacts?.phone,
            website: contacts?.website,
            facebook: socialNetworks?.facebook,
            instagram: socialNetworks?.instagram,
            linkedIn: socialNetworks?.linkedIn,
            twitter: socialNetworks?.twitter,
            youTube: socialNetworks?.youTube
        }
    }
}