import React from "react";
import { Paper, Grid, Typography, makeStyles, Icon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 150,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  label: {
      color: 'grey',
      fontSize: 16
  },
  value: {
    fontSize: 20
}
}));

type CardProps = {
    label: string,
    value: string,
    icon: string
}

export default function Card(props: CardProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Icon>{props.icon}</Icon>
          </Grid>
          <Grid item xs>
            <Typography gutterBottom variant="subtitle2" className={classes.label}>
              {props.label}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.value}>
              {props.value}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
