import { BASE_ERROR } from "../../redux/base/BaseActionTypes";
import { EDIT_UTILITY_ERROR, GET_UTILITY_ERROR, UTILITY_GET_LOGOS_ERROR } from "../../redux/utility/UtilityActionTypes";
import { GET_UTILITY_LIST_FAIL } from "../../redux/utilityList/UtilityListActionTypes";
import { ErrorCodes } from "../error/ErrorCodes";
import ErrorProcessor from "../error/ErrorProcessor";

export default class UtilityErrors {
    private _processor = new ErrorProcessor(
        this.format("Internal Server Error", BASE_ERROR),
        [
            {
                condition: (err) => err.message.includes('[getUtilityList]'),
                value: () => this.format(ErrorCodes.GET_UTILITY_LIST_FAIL, GET_UTILITY_LIST_FAIL)
            },
            {
                condition: (err) => err.message.includes('[getUtility]'),
                value: () => this.format(ErrorCodes.GET_UTILITY_FAIL, GET_UTILITY_ERROR)
            },
            {
                condition: (err) => err.message.includes('[editUtility]'),
                value: () => this.format(ErrorCodes.EDIT_UTILITY_FAIL, EDIT_UTILITY_ERROR)
            },
            {
                condition: (err) => err.message.includes('[getUtilityLogos]'),
                value: () => this.format(ErrorCodes.UTILITY_GET_LOGOS_FAIL, UTILITY_GET_LOGOS_ERROR)
            }
        ]
    )

    get processor() {
        return this._processor;
    }

    private format(message: string, actionType: string) {
        return { message, actionType };
    }
}