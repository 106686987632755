import NumericId from "../../../../base/valueObject/uniqueEntityID/NumericId";
import Mapping, { MappingUpdatableData } from "../../entity/Mapping";
import BuildingType from "../../valueObject/BuildingType";
import HeaterType from "../../valueObject/HeaterType";

export class EditMappingFactoryError extends Error {
    constructor(message: string) {
        super(`[MappingFactory] Error - ${message}`)
    }
}

export default class MappingFactory {
    public static create(id: NumericId, inputData: MappingUpdatableData): Mapping {
        return new Mapping({
            buildingType: BuildingType.create(inputData.buildingType),
            roomsQuantity: inputData.roomsQuantity,
            tumbleDrier: inputData.tumbleDrier,
            electricityWaterHeater: inputData.electricityWaterHeater,
            electricityHeater: inputData.electricityHeater,
            heaterType: inputData.heaterType ? HeaterType.create(inputData.heaterType) : undefined,
            standardProfileId: NumericId.create(inputData.standardProfileId)
        }, id)
    }
}
