import MaterialTable from "material-table";
import { Chip, Grid, makeStyles } from "@material-ui/core";
import { MappingViewModel } from "../../presenter/mapping/viewModel/MappingViewModel";

interface MappingTableProps {
  mappingList: MappingViewModel[];
  standardProfileLookup: object;
  handleEdit: (
    newData: MappingViewModel,
    oldData: MappingViewModel | undefined
  ) => Promise<any>;
  handleDelete: (
    rowData: MappingViewModel | MappingViewModel[]
  ) => Promise<any>;
  handleAdd: (rowData: MappingViewModel) => Promise<any>;
}

const useStyles = makeStyles(() => ({
  icon: {
    display: "inline-flex",
    verticalAlign: "middle",
    fontSize: "large",
  },
  chipYes: {
    color: "#2CDEA8",
  },
  chipNo: {
    color: "#FF7F7F",
  },
}));

export default function MappingTable(props: MappingTableProps) {
  const classes = useStyles();
  const {
    mappingList,
    standardProfileLookup,
    handleEdit,
    handleDelete,
    handleAdd,
  } = props;
  const booleanLookup = { true: "true", false: "false" };
  const numericLookup = [1, 2, 3, 4, 5, 6].reduce(
    (acc, cur) => ({ ...acc, [cur]: cur }),
    {}
  );

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      <MaterialTable
        columns={[
          {
            title: "Building Type",
            field: "buildingType",
            lookup: { house: "house", apartment: "apartment" },
            filterPlaceholder: "filter data",
          },
          {
            title: "Room Quantity",
            field: "roomsQuantity",
            filterPlaceholder: "filter data",
            lookup: numericLookup,
          },
          {
            title: "Tumble Drier",
            field: "tumbleDrier",
            filterPlaceholder: "filter data",
            lookup: booleanLookup,
            render: (rowData: MappingViewModel) => {
              return rowData.tumbleDrier === "true" ? (
                <Chip
                  label="yes"
                  className={classes.chipYes}
                  variant="outlined"
                />
              ) : (
                <Chip
                  label="no"
                  className={classes.chipNo}
                  variant="outlined"
                />
              );
            },
          },
          {
            title: "Water Heating",
            field: "electricityWaterHeater",
            filterPlaceholder: "filter data",
            lookup: booleanLookup,
            render: (rowData: MappingViewModel) => {
              return rowData.electricityWaterHeater === "true" ? (
                <Chip
                  label="yes"
                  className={classes.chipYes}
                  variant="outlined"
                />
              ) : (
                <Chip
                  label="no"
                  className={classes.chipNo}
                  variant="outlined"
                />
              );
            },
          },
          {
            title: "Electric Heating",
            field: "electricityHeater",
            filterPlaceholder: "filter data",
            lookup: booleanLookup,
            render: (rowData: MappingViewModel) => {
              return rowData.electricityHeater === "true" ? (
                <Chip
                  label="yes"
                  className={classes.chipYes}
                  variant="outlined"
                />
              ) : (
                <Chip
                  label="no"
                  className={classes.chipNo}
                  variant="outlined"
                />
              );
            },
          },
          {
            title: "Heater Type",
            field: "heaterType",
            filterPlaceholder: "filter data",
            lookup: {
              "heat pump": "heat pump",
              resistance: "electric resistance",
              none: "none",
            },
            render: (rowData: MappingViewModel) => {
              return rowData.heaterType !== "none" ? (
                rowData.heaterType
              ) : (
                <Chip
                  label="none"
                  className={classes.chipNo}
                  variant="outlined"
                />
              );
            },
          },
          {
            title: "Standard Profile",
            field: "standardProfileName",
            filterPlaceholder: "filter data",
            lookup: standardProfileLookup,
          },
        ]}
        data={mappingList}
        editable={{
          onRowAdd: handleAdd,
          onRowUpdate: handleEdit,
          onRowDelete: handleDelete,
        }}
        options={{
          filtering: true,
          search: false,
          showTitle: false,
          pageSize: 10,
          pageSizeOptions: [10, 15, 20, 25],
          emptyRowsWhenPaging: false,
          toolbar: true,
          actionsColumnIndex: -1,
          addRowPosition: "first",
        }}
      />
    </Grid>
  );
}
