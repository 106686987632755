import { ValueObject } from "../../../base/valueObject/ValueObject";
import Mapping from "../entity/Mapping";

export class MappingListError extends Error {
    constructor(message: string) {
        super(`[MappingList] Error - ${message}`)
    }
}

interface MappingListProps {
    mappings: Mapping[]
}

export default class MappingList extends ValueObject<MappingListProps> {
    get mappings(): Mapping[] {
        return this.props.mappings;
    }

    private constructor(props: MappingListProps) {
        super(props);
    }

    public static create(mappings: Mapping[]) {
        if (!mappings)
            throw new MappingListError(`Mapping list must not be undefined`);
        else return new MappingList({ mappings })
    }

    public equals(vo: ValueObject<MappingListProps>): boolean {
        if (this.props.mappings.length !== vo.props.mappings.length)
            return false

        let result = this.props.mappings.filter(m1 => vo.props.mappings.some(m2 => m1.id.isEqual(m2.id)));       
        return result.length === this.props.mappings.length
    }
}