import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class NameError extends Error {
    constructor(message: string) {
        super(`[Name] Error - ${message}`);
    }
}

interface NameProps {
    firstName: NotEmptyString;
    lastName: NotEmptyString
}

export default class Name extends ValueObject<NameProps> {

    get firstName(): string {
        return this.props.firstName.value;
    }

    get lastName(): string {
        return this.props.lastName.value;
    }

    private constructor(props: NameProps) {
        super(props);
    }

    public static create(firstName: string, lastName: string): Name {
        try {
            return new Name({
                firstName: NotEmptyString.create(firstName, "firstName"),
                lastName: NotEmptyString.create(lastName, "lastName")
            })
        }
        catch (error) {
            const field = error.message?.indexOf("firstName") !== -1 ? "firstName" : "lastName";
            throw new NameError(`Provided ${field} is empty string`);
        }
    }

    public equals(vo: ValueObject<NameProps>): boolean {
        return vo.props.firstName.equals(this.props.firstName) && vo.props.lastName.equals(this.props.lastName);
    }

    public static merge(firstName: string | undefined, lastName: string | undefined, oldData: Name): Name {
        return Name.create(
            ValueObject.determineNextValue(firstName, oldData.firstName) as string,
            ValueObject.determineNextValue(lastName, oldData.lastName) as string
        );
    }
}