import NotEmptyString from "../../../domain/base/valueObject/NotEmptyString";
import NumericId from "../../../domain/base/valueObject/uniqueEntityID/NumericId";
import StandardProfile from "../../../domain/modules/standardProfile/entity/StandardProfile";
import StandardProfileList from "../../../domain/modules/standardProfile/valueObject/StandardProfileList";
import IStandardProfileDataSource from "../../dataSource/standardProfileDataSource/IStandardProfileDataSource";
import IStandardProfileMapperFactory from "./factory/IStandardProfileMapperFactory";
import IStandardProfileRepository from "./IStandardProfileRepository";

export class StandardProfileRepositoryError extends Error {
    constructor(message: string) {
        super(`[StandardProfileRepository] Error - ${message}`)
    }
}

export default class StandardProfileRepository implements IStandardProfileRepository {
    constructor(
        private _dataSource: IStandardProfileDataSource,
        private _mapperFactory: IStandardProfileMapperFactory
    ) { }

    async getStandardProfiles(): Promise<StandardProfileList> {
        try {
            const profiles = await this._dataSource.getStandardProfiles();
            const profilesMap = profiles.map(profileData => this._mapperFactory.getStandardProfileMapper().map(profileData))
            return StandardProfileList.create(profilesMap)
        } catch (error) {
            throw new StandardProfileRepositoryError(`[getStandardProfiles] - ${error.message}`)
        }
    }

    findStandardProfileId(name: NotEmptyString, list: StandardProfileList): NumericId {
        try {
            return list.profiles.filter(p => p.name.equals(name))[0].id
        } catch (err) {
            throw new StandardProfileRepositoryError(`[findStandardProfileById] - ${err.message}`)
        }
    }

    async editStandardProfile(id: NumericId, standardProfile: StandardProfile): Promise<StandardProfile> {
        try {
            await this._dataSource.editStandardProfile(id.getId(), this._mapperFactory.getStandardProfileDataMapper().map(standardProfile));
            return standardProfile
        } catch (e) {
            throw new StandardProfileRepositoryError(`[editStandardProfile] - ${e.message}`)
        }
    }

    findStandardProfileById(id: NumericId, profileList: StandardProfileList): StandardProfile {
        return profileList.profiles.filter(p => p.id.equals(id))[0]
    }
}