import IStandardProfileGateway from "../gateway/IStandardProfileGateway";
import StandardProfileList from "../valueObject/StandardProfileList";

export class GetStandardProfilesInteractorError extends Error {
    constructor(message: string) {
        super(`[GetStandardProfiles] Error - ${message}`)
    }
}

export interface IGetStandardProfilesInput {
    getStandardProfiles(): Promise<void>;
}

export interface IGetStandardProfilesOutput {
    displaySuccessResponse(profiles: StandardProfileList): void;
    displayErrorResponse(error: Error): void;
}

export class GetStandardProfilesInteractor implements IGetStandardProfilesInput {
    constructor(
        private _output: IGetStandardProfilesOutput,
        private _gateway: IStandardProfileGateway
    ) { }

    public async getStandardProfiles(): Promise<void> {
        try {
            await this.interact()
        } catch (e) {
            this._output.displayErrorResponse(new GetStandardProfilesInteractorError(e.message))
        }
    }

    private async interact() {
        this._output.displaySuccessResponse(
            await this._gateway.getStandardProfiles()
        )
    }
}