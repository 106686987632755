import NumericId from "../../../../domain/base/valueObject/uniqueEntityID/NumericId";
import Mapping from "../../../../domain/modules/mapping/entity/Mapping";
import BuildingType from "../../../../domain/modules/mapping/valueObject/BuildingType";
import HeaterType from "../../../../domain/modules/mapping/valueObject/HeaterType";
import { MappingData } from "../../../dataSource/mappingDataSource/IMappingDataSource";
import IMapper from "../../../IMapper";

export default class MappingMapper implements IMapper<MappingData, Mapping> {
    map(input: MappingData): Mapping {
        const { id, buildingType, roomsQuantity, tumbleDrier, electricityWaterHeater, electricityHeater, heaterType, standardProfileId } = input

        return new Mapping({
            buildingType: BuildingType.create(buildingType),
            roomsQuantity: roomsQuantity,
            tumbleDrier: tumbleDrier,
            electricityWaterHeater: electricityWaterHeater,
            electricityHeater: electricityHeater,
            heaterType: heaterType ? HeaterType.create(heaterType) : undefined,
            standardProfileId: NumericId.create(standardProfileId)
        }, NumericId.create(id))
    }
}