import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import NumericId from "../../../../domain/base/valueObject/uniqueEntityID/NumericId";
import Mapping from "../../../../domain/modules/mapping/entity/Mapping";
import IMappingGateway from "../../../../domain/modules/mapping/gateway/IMappingGateway";
import { MappingInputs } from "../../../../domain/modules/mapping/useCase/processor/AddMappingInputProcessor";
import StandardProfileList from "../../../../domain/modules/standardProfile/valueObject/StandardProfileList";
import IStandardProfileRepository from "../../standardProfileRepository/IStandardProfileRepository";
import IMappingRepository from "../IMappingRepository";

export class MappingGatewayError extends Error {
    constructor(message: string) {
        super(`[MappingGateway] Error - ${message}`)
    }
}

export default class MappingGateway implements IMappingGateway {
    constructor(
        private mappingRepository: IMappingRepository,
        private _standardProfileRepository: IStandardProfileRepository
    ) { }

    public async getStandardProfiles(): Promise<StandardProfileList> {
        try {
            return await this._standardProfileRepository.getStandardProfiles();
        } catch (err) {
            throw new MappingGatewayError(`[getStandardProfiles] - ${err.message}`)
        }
    }

    public findStandardProfileId(profileName: NotEmptyString, profileList: StandardProfileList): NumericId {
        try {
            return this._standardProfileRepository.findStandardProfileId(profileName, profileList);
        } catch (err) {
            throw new MappingGatewayError(`[findStandardProfileId] - ${err.message}`)
        }
    }

    public async editMapping(mappingId: NumericId, mapping: Mapping): Promise<Mapping> {
        try {
            return await this.mappingRepository.editMapping(mappingId, mapping)
        } catch (err) {
            throw new MappingGatewayError(`[editMapping] - ${err.message}`)
        }
    }

    public async addMapping(mapping: MappingInputs): Promise<Mapping> {
        try {
            return await this.mappingRepository.addMapping(mapping)
        } catch (err) {
            throw new MappingGatewayError(`[addMapping] - ${err.message}`)
        }
    }
}