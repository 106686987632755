import { ISignOutOutput } from "../../../domain/modules/auth/useCase/SignOutUseCase";
import { BASE_SET_FLAGS } from "../../redux/base/BaseActionTypes";
import { SIGN_OUT_SUCCESS } from "../../redux/signOut/SignOutActionTypes";
import ReduxDispatch from "../ReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import SignOutErrors from "./SignOutErrors";

export default class SignOutPresenter extends ReduxPresenter implements ISignOutOutput {

    constructor(dispatch: ReduxDispatch) {
        super(dispatch, new SignOutErrors().processor);
    }
    
    displaySuccessResponse(): void {
        this.dispatch({
            type: SIGN_OUT_SUCCESS
        })
        this.dispatch({
            type: BASE_SET_FLAGS,
            payload: { ready: true, isSignedIn: false }
        })
    }
}