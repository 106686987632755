import { MappingInputs } from "../../../../domain/modules/mapping/useCase/processor/AddMappingInputProcessor";
import { MappingInput } from "../../../dataSource/mappingDataSource/IMappingDataSource";
import IMapper from "../../../IMapper";

export default class MappingMapper implements IMapper<MappingInputs, MappingInput> {
    map(input: MappingInputs): MappingInput {
        return {
            buildingType: input.buildingType.value,
            roomsQuantity: input.roomsQuantity,
            tumbleDrier: input.tumbleDrier,
            electricityWaterHeater: input.electricityWaterHeater,
            electricityHeater: input.electricityHeater,
            heaterType: input.heaterType?.value,
            standardProfileId: input.standardProfileId.getId()
        }
    }
}