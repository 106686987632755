import Utility from "../../../../domain/modules/utility/entity/Utility";
import UtilityLogo from "../../../../domain/modules/utility/valueObject/UtilityLogo";
import { UtilityData, UtilityEditData, UtilityLogoData } from "../../../dataSource/utilityDataSource/IUtilityDataSource";
import IMapper from "../../../IMapper";
import UtilityEditDataMapper from "../mapper/UtilityEditDataMapper";
import UtilityLogoMapper from "../mapper/UtilityLogoMapper";
import UtilityMapper from "../mapper/UtilityMapper";
import IUtilityRepositoryMapperFactory from "./IUtilityRepositoryMapperFactory";

export default class UtilityRepositoryMapperFactory implements IUtilityRepositoryMapperFactory {
    getUtilityMapper(): IMapper<UtilityData, Utility> {
        return new UtilityMapper();
    }

    getUtilityEditDataMapper(): IMapper<Utility, UtilityEditData> {
        return new UtilityEditDataMapper();
    }
    
    getUtilityLogosMapper(): IMapper<UtilityLogoData, UtilityLogo> {
        return new UtilityLogoMapper();
    }
}