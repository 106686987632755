import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import Utility from "../../../../domain/modules/utility/entity/Utility";
import { UtilityData } from "../../../dataSource/utilityDataSource/IUtilityDataSource";
import IMapper from "../../../IMapper";
import Logos from "../../../../domain/modules/utility/valueObject/Logos";
import Contacts from "../../../../domain/modules/utility/valueObject/Contacts";
import SocialNetworks from "../../../../domain/modules/utility/valueObject/SocialNetworks";
import Description from "../../../../domain/modules/utility/valueObject/Description";
import NumericId from "../../../../domain/base/valueObject/uniqueEntityID/NumericId";

export default class UtilityMapper implements IMapper<UtilityData, Utility> {
    map(input: UtilityData): Utility {
        const {
            id,
            name,
            shortName,
            description,
            logoLight, logoDark,
            address, email, phone, website,
            facebook, twitter, instagram, linkedIn, youTube
        } = input;

        return new Utility({
            name: NotEmptyString.create(name),
            shortName: NotEmptyString.create(shortName),
            description: Description.create(description),
            logos: Logos.create(logoLight, logoDark),
            contacts: Contacts.create(address, email, phone, website),
            socialNetworks: SocialNetworks.create({
                facebook: facebook,
                twitter: twitter,
                instagram: instagram,
                linkedIn: linkedIn,
                youTube: youTube
            })
        }, NumericId.create(id))
    }
}