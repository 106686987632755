import UtilityLogo from "../../../../domain/modules/utility/valueObject/UtilityLogo";
import { UtilityLogoData } from "../../../dataSource/utilityDataSource/IUtilityDataSource";
import IMapper from "../../../IMapper";

export default class UtilityLogoMapper implements IMapper<UtilityLogoData, UtilityLogo> {
    map(input: UtilityLogoData): UtilityLogo {
        const { name, url } = input;

        return UtilityLogo.create(name, url);
    }
}