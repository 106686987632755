import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import { UtilityViewModel } from "../../../../gui/presenter/utility/viewmodel/UtilityViewModel";
import { LogoFile } from "../../../../gui/redux/utility/UtilityReducer";
import IEditUtilityGateway from "../gateway/IEditUtilityGateway";
import EditUtilityFactory from "./factory/EditUtilityFactory";

export class EditUtilityInteractorError extends Error {
    constructor(message: string) {
        super(`[EditUtilityUseCase] Error - ${message}`);
    }
}

export interface IEditUtilityInput {
    editUtility(utility: UtilityViewModel, file: LogoFile): Promise<void>;
}

export class EditUtilityInteractor implements IEditUtilityInput {

    constructor(
        private _output: IBasicOutput<void>,
        private _gateway: IEditUtilityGateway
    ) { }

    public async editUtility(utility: UtilityViewModel, file?: LogoFile): Promise<void> {
        try {
            await this.interact(utility, file);
        } catch (error) {
            this._output.displayErrorResponse(error);
        }
    }

    private async interact(utility: UtilityViewModel, file?: LogoFile) {
        if (file !== undefined) {
            if (file.logoLight !== undefined) {
                const updatedLogoLight = await this._gateway.uploadLogo(file.logoLight);
                utility.logoLight = updatedLogoLight.url;
            }

            if (file.logoDark !== undefined) {
                const updatedLogoDark = await this._gateway.uploadLogo(file.logoDark);
                utility.logoDark = updatedLogoDark.url;
            }
        }

        const utilityEntity = EditUtilityFactory.create({ ...utility });
        await this._gateway.editUtility(utilityEntity);
        this._output.displaySuccessResponse();
    }

}