import TestUserDataFactory from "../../../test/factory/TestUserDataFactory";
import NetworkController from "../../network/NetworkController";
import IUserDataSource, { UserData } from "./IUserDataSource";

export class UserDataSourceError extends Error {
    constructor(message: string) {
        super(`[UserDataSource] Error - ${message}`);
    }
}

export default class UserDataSource implements IUserDataSource {

    constructor(private nwc: NetworkController) { }

    async getUsers(): Promise<UserData[]> {
        try {
            const users: any = await this.nwc.request({
                url: "/admin/users",
                method: "GET",
                useToken: true
            })
            return users.data;
        }
        catch (error) {
            throw new UserDataSourceError(`[getUsers] - ${error.message}`)
        }
    }

    async getUser(id: string): Promise<UserData> {
        try {
            const user: any = await this.nwc.request({
                url: `/admin/users/${id}`,
                method: "GET",
                useToken: true
            })
            return user.data;
        }
        catch (error) {
            throw new UserDataSourceError(`[getUser] - ${error.message}`)
        }
    }

    async editUser(id: string, user: UserData): Promise<void> {
        try {
            const data = new URLSearchParams()
            data.append("zipCode", user.zipCode)
            data.append("utilityId", user.utilityId);

            await this.nwc.request({
                url: `/admin/users/${id}`,
                method: "PUT",
                data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                useToken: true

            })
        }
        catch (error) { 
            throw new UserDataSourceError(`[editUser] - ${error.message}`)
        }
    }

    getUsersMock(count: number): UserData[] {
        const data = []

        for (let i = 1; i <= count; i++) {
            data.push(
                TestUserDataFactory.createUserData({
                    id: 'user-id' + i,
                    utilityId: 'utility' + i
                }))
        }

        return data
    }
}