import { ValueObject } from "../../../base/valueObject/ValueObject";

export class BuildingTypeError extends Error {
    constructor(message: string) {
        super(`[BuildingType] Error - ${message}`)
    }
}

interface BuildingTypeProps {
    value: string;
}

export default class BuildingType extends ValueObject<BuildingTypeProps> {
    get value(): string {
        return this.props.value;
    }

    private constructor(props: BuildingTypeProps) {
        super(props);
    }

    public static create(value: string): BuildingType {
        if (!BuildingType.validate(value))
            throw new BuildingTypeError(`Provided housing type is not valid. Housing type - ${value}`)
        else
            return new BuildingType({ value });
    }

    private static validate(value: string): boolean {
        if (!value) return false
        return ["house", "apartment"].indexOf(value.toLocaleLowerCase()) !== -1;
    }

    public equals(vo: ValueObject<BuildingTypeProps>): boolean {
        return vo.props.value === this.props.value
    }
}