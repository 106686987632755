import { BASE_ERROR } from "../../redux/base/BaseActionTypes";
import { GET_STANDARD_PROFILES_FAIL, UPDATE_STANDARD_PROFILE_FAIL } from "../../redux/standardProfileList/StandardProfileListActionTypes";
import { ErrorCodes } from "../error/ErrorCodes";
import ErrorProcessor from "../error/ErrorProcessor";

export default class StandardProfileErrors {
    private _processor = new ErrorProcessor(
        this.format("Internal Server Error", BASE_ERROR),
        [{
            condition: (err) => err.message.includes('[getStandardProfiles]'),
            value: () => this.format(ErrorCodes.GET_STANDARD_PROFILES_FAIL, GET_STANDARD_PROFILES_FAIL)
        },
        {
            condition: (err) => err.message.includes('[editStandardProfile]'),
            value: () => this.format(ErrorCodes.UPDATE_STANDARD_PROFILE_FAILS, UPDATE_STANDARD_PROFILE_FAIL)
        }
    ]
    )
    
    get processor() {
        return this._processor;
    }

    private format(message: string, actionType: string) {
        return { message, actionType };
    }
}