import { SignInActionTypes } from "./SignInActionTypes";
import { SIGN_IN_LOADING, SIGN_IN_SUCCESS, SIGN_IN_FAIL, SIGN_IN_INPUTS } from "./SignInActionTypes";

interface SignInState {
    email: string;
    password: string;
    isLoading: boolean;
    error?: string;
}

const defaultState: SignInState = {
    email: "",
    password: "",
    isLoading: false
}

const signInReducer = (state: SignInState = defaultState, action: SignInActionTypes): SignInState => {
    switch (action.type) {
        case SIGN_IN_LOADING:
            return { ...state, isLoading: true }
        case SIGN_IN_FAIL:
            return { ...state, isLoading: false, error: action.payload.errorMessage }
        case SIGN_IN_SUCCESS:
            return { ...defaultState }
        case SIGN_IN_INPUTS:
            return { ...state, [action.payload.field]: action.payload.value }
        default:
            return state;
    }
}

export default signInReducer;