import { Dispatch } from "redux";
import { dependencyContainer } from "../../..";
import { SignOutInteractor } from "../../../domain/modules/auth/useCase/SignOutUseCase";
import ReduxDispatch from "../../presenter/ReduxDispatch";
import SignOutPresenter from "../../presenter/signOut/SignOutPresenter";
import { RootStore } from "../Store";

export const SignOutAction = () => async(dispatch: Dispatch, getState: () => RootStore) => {
    const gateway = dependencyContainer.dependency.gatewayFactory.getSignOutGateway()
    new SignOutInteractor(new SignOutPresenter(new ReduxDispatch(dispatch)), gateway)
        .signOut()
}