import { StandardProfileViewModel } from "../../presenter/standardProfile/viewModel/StandardProfileViewModel";

export const GET_STANDARD_PROFILES_FAIL = "GET_STANDARD_PROFILES_FAIL";
export const GET_STANDARD_PROFILES_LOADING = "GET_STANDARD_PROFILES_LOADING";
export const GET_STANDARD_PROFILES_SUCCESS = "GET_STANDARD_PROFILES_SUCCESS";
export const UPDATE_STANDARD_PROFILE = "UPDATE_STANDARD_PROFILE";
export const UPDATE_STANDARD_PROFILE_FAIL = "UPDATE_STANDARD_PROFILE_FAIL";

interface GetStandardProfilesLoading {
    type: typeof GET_STANDARD_PROFILES_LOADING
}

interface GetStandardProfilesFail {
    type: typeof GET_STANDARD_PROFILES_FAIL,
    payload: {
        errorMessage: string;
    }
}

interface GetStandardProfilesSuccess {
    type: typeof GET_STANDARD_PROFILES_SUCCESS,
    payload: {
        profilesList: StandardProfileViewModel[]
    }
}

interface UpdateStandardProfile {
    type: typeof UPDATE_STANDARD_PROFILE,
    payload: {
        standardProfile: StandardProfileViewModel
    }
}

interface UpdateStandardProfileFail {
    type: typeof UPDATE_STANDARD_PROFILE_FAIL;
    payload: {
        errorMessage: string;
    };
}

export type StandardProfilesActionTypes = GetStandardProfilesLoading | GetStandardProfilesFail | GetStandardProfilesSuccess
    | UpdateStandardProfile | UpdateStandardProfileFail;