import UtilityLogo from "../../../domain/modules/utility/valueObject/UtilityLogo";
import {
    UtilityActionTypes,
    EDIT_UTILITY_INPUT,
    EDIT_UTILITY_ERROR,
    EDIT_UTILITY_RESET,
    EDIT_UTILITY_LOADING,
    UTILITY_GET_LOGOS_SUCCESS,
    UTILITY_GET_LOGOS_ERROR,
    UTILITY_LOGO_SET_SELECTED,
    UTILITY_LOGO_UPDATE,
    EDIT_UTILITY_SUCCESS,
    UTILITY_LOGO_FILE_UPLOAD,
    UTILITY_LOGO_RESET_SELECTED,
    GET_UTILITY_SUCCESS,
    GET_UTILITY_ERROR
} from "./UtilityActionTypes";

export type LogoFile = {
    logoLight?: File;
    logoDark?: File;
}

interface UtilityState {
    id: string;
    name: string;
    shortName: string;
    description: string;
    logoLight: string;
    logoDark: string;
    address: string;
    email: string;
    phone: string;
    website: string;
    facebook: string;
    twitter: string;
    instagram: string;
    linkedIn: string;
    youTube: string;
    selectedLogoType: string;
    selectedLogoUrl: string;
    logos: UtilityLogo[];
    file: LogoFile;
    error: string;
    loading: boolean;
    success: boolean;
}

const defaultState: UtilityState = {
    id: "",
    name: "",
    shortName: "",
    description: "",
    logoLight: "",
    logoDark: "",
    address: "",
    email: "",
    phone: "",
    website: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedIn: "",
    youTube: "",
    selectedLogoType: "",
    selectedLogoUrl: "",
    logos: [],
    file: {
        logoLight: undefined,
        logoDark: undefined
    },
    error: "",
    loading: false,
    success: false
}

const utilityReducer = (state: UtilityState = defaultState, action: UtilityActionTypes): UtilityState => {
    switch (action.type) {
        case EDIT_UTILITY_ERROR:
            return { ...state, loading: false, error: action.payload.errorMessage }
        case EDIT_UTILITY_LOADING:
            return { ...state, loading: true }
        case EDIT_UTILITY_SUCCESS:
            return { ...state, success: true }
        case GET_UTILITY_ERROR:
            return { ...state, loading: false, error: action.payload.errorMessage }
        case GET_UTILITY_SUCCESS:
            return {
                ...defaultState,
                id: action.payload.utility.id,
                name: action.payload.utility.name,
                shortName: action.payload.utility.shortName,
                description: action.payload.utility.description,
                logoLight: action.payload.utility.logoLight,
                logoDark: action.payload.utility.logoDark,
                address: action.payload.utility.address,
                email: action.payload.utility.email,
                phone: action.payload.utility.phone,
                website: action.payload.utility.website,
                facebook: action.payload.utility.facebook,
                twitter: action.payload.utility.twitter,
                instagram: action.payload.utility.instagram,
                linkedIn: action.payload.utility.linkedIn,
                youTube: action.payload.utility.youTube
            }
        case EDIT_UTILITY_INPUT:
            return { ...state, [action.payload.input.field]: action.payload.input.value }
        case UTILITY_GET_LOGOS_ERROR:
            return { ...state, loading: false, error: action.payload.errorMessage }
        case UTILITY_GET_LOGOS_SUCCESS:
            return { ...state, loading: false, logos: action.payload.logos }
        case UTILITY_LOGO_RESET_SELECTED:
            return { ...state, selectedLogoType: "", selectedLogoUrl: "" }
        case UTILITY_LOGO_SET_SELECTED:
            return { ...state, selectedLogoType: action.payload.selectedLogoType, selectedLogoUrl: action.payload.selectedLogoUrl }
        case UTILITY_LOGO_UPDATE:
            return { ...state, [state.selectedLogoType]: action.payload.url }
        case UTILITY_LOGO_FILE_UPLOAD:
            return {
                ...state, file: {
                    ...state.file,
                    [state.selectedLogoType]: action.payload.file
                }
            }
        case EDIT_UTILITY_RESET:
            return { ...defaultState }
        default:
            return state
    }
}


export default utilityReducer;