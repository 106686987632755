import StandardProfile from "../../../../domain/modules/standardProfile/entity/StandardProfile";
import { StandardProfileData } from "../../../dataSource/standardProfileDataSource/IStandardProfileDataSource";
import IMapper from "../../../IMapper";
import StandardProfileDataMapper from "../mapper/StandardProfileDataMapper";
import StandardProfileMapper from "../mapper/StandardProfileMapper";
import IStandardProfileRepositoryMapperFactory from "./IStandardProfileMapperFactory";

export default class StandardProfileRepositoryMapperFactory implements IStandardProfileRepositoryMapperFactory {
    getStandardProfileMapper(): IMapper<StandardProfileData, StandardProfile> {
        return new StandardProfileMapper();
    }

    getStandardProfileDataMapper(): IMapper<StandardProfile, StandardProfileData> {
        return new StandardProfileDataMapper();
    }
}