import TestStandardProfileDataFactory from "../../../domain/modules/standardProfile/test/factory/TestStandardProfileDataFactory";
import NetworkController from "../../network/NetworkController";
import IStandardProfileDataSource, { StandardProfileData } from "./IStandardProfileDataSource";

export class StandardProfileDataSourceError extends Error {
    constructor(message: string) {
        super(`[StandardProfileDataSource] Error - ${message}`)
    }
}

export default class StandardProfileDataSource implements IStandardProfileDataSource {
    constructor(private nwc: NetworkController) { }

    async getStandardProfiles(): Promise<StandardProfileData[]> {
        try {
            const profiles: any = await this.nwc.request({
                url: "/admin/standard-profiles",
                method: "GET",
                useToken: true
            })
            
            return profiles.data
        } catch (e) {
            throw new StandardProfileDataSourceError(`[getStandardProfiles] - ${e.message}`)
        }
    }

    async editStandardProfile(standardProfileId: number, standardProfile: StandardProfileData): Promise<void> {
        try {
            const data = new URLSearchParams()
            data.append("name", standardProfile.name);
            data.append("description", standardProfile.description);
            data.append("siotRemoteSource", standardProfile.siotRemoteSource);

            await this.nwc.request({
                url: `/admin/standard-profiles/${standardProfileId}`,
                method: "PUT",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                useToken: true,
                data
            })
        } catch (error) {
            throw new StandardProfileDataSourceError(`[editStandardProfile] - ${error.message}`)
        }
    }

    getStandardProfilesMock(count: number): StandardProfileData[] {
        const data: StandardProfileData[] = []

        for (let i = 1; i <= count; i++) {
            data.push(
                TestStandardProfileDataFactory.createStandardProfileData({
                    id: 1,
                    name: `h${i}-h${++i}`
                }))
        }
        return data
    }
}