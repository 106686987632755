import { BASE_SNACK_CLOSE } from "../../redux/base/BaseActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import MappingErrors from "./MappingErrors";

export default class RecalculateStandardProfilesPresenter extends ReduxPresenter implements IBasicOutput<void> {

    constructor(dispatch: IReduxDispatch) {
        super(dispatch, new MappingErrors().processor);
    }

    displaySuccessResponse(): void {
        setTimeout(() => {
            this.dispatch({
                type: BASE_SNACK_CLOSE
            })
        }, 15000)
    }
}