import { ValueObject } from "../../../base/valueObject/ValueObject";

interface UserNotificationInfoProps {
    utility?: boolean;
    tips?: boolean;
    progress?: boolean;
}

export default class UserNotificationInfo extends ValueObject<UserNotificationInfoProps> {

    get utility(): boolean | undefined {
        return this.props.utility;
    }

    get tips(): boolean | undefined {
        return this.props.tips;
    }

    get progress(): boolean | undefined {
        return this.props.progress;
    }

    private constructor(props: UserNotificationInfoProps) {
        super(props);
    }

    public static create(utility: boolean | undefined, tips: boolean | undefined, progress: boolean | undefined): UserNotificationInfo {
        return new UserNotificationInfo({ utility, tips, progress })
    }

    public equals(vo: ValueObject<UserNotificationInfoProps>): boolean {
        return vo.props.utility === this.props.utility &&
            vo.props.tips === this.tips &&
            vo.props.progress === this.progress;
    }

}