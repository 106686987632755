import { StandardProfileViewModel } from "../../presenter/standardProfile/viewModel/StandardProfileViewModel";
import {
    GET_STANDARD_PROFILES_FAIL, GET_STANDARD_PROFILES_LOADING, GET_STANDARD_PROFILES_SUCCESS,
    StandardProfilesActionTypes, UPDATE_STANDARD_PROFILE, UPDATE_STANDARD_PROFILE_FAIL
} from "./StandardProfileListActionTypes";

interface StandardProfileListState {
    profilesList: StandardProfileViewModel[];
    loading: boolean;
    error?: string;
}

const defaultState: StandardProfileListState = {
    profilesList: [],
    loading: false
}

const standardProfilesReducer = (state: StandardProfileListState = defaultState, action: StandardProfilesActionTypes): StandardProfileListState => {
    switch (action.type) {
        case GET_STANDARD_PROFILES_LOADING: {
            return { ...state, loading: true }
        }
        case GET_STANDARD_PROFILES_FAIL: {
            return { ...state, loading: false, error: action.payload.errorMessage }
        }
        case GET_STANDARD_PROFILES_SUCCESS: {
            return { ...state, loading: false, profilesList: action.payload.profilesList }
        }
        case UPDATE_STANDARD_PROFILE:
            const newList = state.profilesList.map(p => {
                if (p.id === action.payload.standardProfile.id) {
                    return action.payload.standardProfile
                } else return p
            })
            return { ...state, profilesList: newList }
        case UPDATE_STANDARD_PROFILE_FAIL:
            return { ...state, error: action.payload.errorMessage }
        default:
            return state
    }
}

export default standardProfilesReducer;