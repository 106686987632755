import IGetUsersGateway from "../gateway/IGetUsersGateway";
import UserList from "../valueObject/UserList";

export class GetUsersInteractorError extends Error {
    constructor(message: string) {
        super(`[GetUsersUseCase] Error - ${message}`)
    }
}

export interface IGetUsersInput {
    getUsers(): Promise<void>;
}

export interface IGetUsersOutput {
    displaySuccessResponse(userList: UserList): void;
    displayErrorResponse(error: Error): void;
}

export class GetUsersInteractor implements IGetUsersInput {

    constructor(
        private _output: IGetUsersOutput,
        private _gateway: IGetUsersGateway
    ) { }

    public async getUsers(): Promise<void> {
        try {
            await this.interact()
        } catch (e) {
            this._output.displayErrorResponse(new GetUsersInteractorError(e.message))
        }
    }

    private async interact() {
        const userList = await this._gateway.getUsers()

        this._output.displaySuccessResponse(userList)
    }

}