import { Grid, Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../../components/CustomDialog";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import Snackbar from "../../../components/Snackbar";
import MappingTable from "../../../components/table/MappingTable";
import { MappingViewModel } from "../../../presenter/mapping/viewModel/MappingViewModel";
import { SnackClose } from "../../../redux/base/BaseActions";
import {
  AddMapping,
  DeleteMapping,
  EditMapping,
  GetMappingsAction,
  RecalculateStandardProfiles,
} from "../../../redux/mappingList/MappingListActions";
import { RootStore } from "../../../redux/Store";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(-3),
  },
  button: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    }
  },
}));

export default function Mappings() {
  const classes = useStyles();
  const [openSubmit, setOpenSubmit] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);

  const { mappingList, error, loading } = useSelector(
    (state: RootStore) => state.mappingListReducer
  );
  const standardProfileList = useSelector(
    (state: RootStore) => state.standardProfileListReducer.profilesList
  );

  const { snackOpen, snackText } = useSelector(
    (state: RootStore) => state.baseReducer
  );

  const standardProfileLookup = standardProfileList
    .map((p) => p.name)
    .reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetMappingsAction());
  }, [dispatch]);

  const handleEdit = (
    newData: MappingViewModel,
    oldData: MappingViewModel | undefined
  ) => {
    dispatch(EditMapping(newData));
    return Promise.resolve();
  };

  const handleDelete = (rowData: MappingViewModel | MappingViewModel[]) => {
    dispatch(DeleteMapping((rowData as MappingViewModel).id));
    return Promise.resolve();
  };

  const handleAdd = (rowData: MappingViewModel) => {
    dispatch(AddMapping(rowData));
    return Promise.resolve();
  };

  const openSubmitDialog = (event: any) => {
    event.preventDefault();
    setOpenSubmit(true);
  };

  const handleCloseDialog = () => {
    if (openCancel) setOpenCancel(false);
    if (openSubmit) setOpenSubmit(false);
  };

  const handleSubmitDialog = () => {
    dispatch(RecalculateStandardProfiles());
    setOpenSubmit(false);
  };

  const handleSnackBarClose = () => {
    dispatch(SnackClose());
  };

  const viewToRender = (
    <div className={classes.main}>
      <MappingTable
        standardProfileLookup={standardProfileLookup}
        mappingList={mappingList}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
      />
      <Grid item xs={3}>
        <Button
          className={classes.button}
          size="medium"
          onClick={openSubmitDialog}
        >
          Recalculate standard profile for all users
        </Button>
      </Grid>
      <CustomDialog
        open={openSubmit}
        onClose={handleCloseDialog}
        title="Start recalculation?"
        contentText="This action will start recalculation of standard profiles for all users."
        actions={[
          {
            text: "Cancel",
            click: handleCloseDialog,
            primary: false,
          },
          {
            text: "Save",
            click: handleSubmitDialog,
            primary: true,
          },
        ]}
      />
      <Snackbar
        open={snackOpen}
        color={"success"}
        close={true}
        closeNotification={handleSnackBarClose}
        message={snackText}
        autoHideDuration={3000}
        place={"br"}
      />
    </div>
  );

  if (!error && !loading) {
    return viewToRender;
  } else if (loading) {
    return <Loader />;
  } else {
    return <ErrorMessage message={error} />;
  }
}
