import IGetUserGateway from "../gateway/IGetUserGateway";
import User from "../entity/User";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";

export class GetUserInteractorError extends Error {
    constructor(message: string) {
        super(`[GetUserUseCase] Error - ${message}`)
    }
}

export interface IGetUserInput {
    getUser(id: string): Promise<void>;
}

export interface IGetUserOutput {
    displaySuccessResponse(user: User): void;
    displayErrorResponse(error: Error): void;
}

export class GetUserInteractor implements IGetUserInput {

    constructor(
        private _output: IGetUserOutput,
        private _gateway: IGetUserGateway
    ) { }

    public async getUser(id: string): Promise<void> {
        try {
            await this.interact(StringId.create(id))
        } catch (e) {
            this._output.displayErrorResponse(new GetUserInteractorError(e.message))
        }
    }

    private async interact(id: StringId) {
        const user = await this._gateway.getUser(id)

        this._output.displaySuccessResponse(user)
    }

}