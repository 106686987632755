import { BASE_ERROR } from "../../redux/base/BaseActionTypes";
import { EDIT_USER_FAIL, GET_USER_FAIL } from "../../redux/user/UserActionTypes";
import { GET_USERS_FAIL } from "../../redux/userList/UserListActionTypes";
import { ErrorCodes } from "../error/ErrorCodes";
import ErrorProcessor from "../error/ErrorProcessor";


export default class UserError {

    private _processor = new ErrorProcessor(
        this.format("Internal Server Error", BASE_ERROR),
        [{
            condition: (err) => err.message.includes('[getUsers]'),
            value: () => this.format(ErrorCodes.GET_USERS_FAIL, GET_USERS_FAIL)
        },
        {
            condition: (err) => err.message.includes('[getUser]'),
            value: () => this.format(ErrorCodes.GET_USERS_FAIL, GET_USER_FAIL)
        },
        {
            condition: (err) => err.message.includes('[editUser]'),
            value: () => this.format(ErrorCodes.EDIT_USER_FAIL, EDIT_USER_FAIL)
        }
        ]
    )

    get processor() {
        return this._processor;
    }

    private format(message: string, actionType: string) {
        return { message, actionType };
    }
}