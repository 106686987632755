import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import NumericId from "../../../../domain/base/valueObject/uniqueEntityID/NumericId";
import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import StandardProfile from "../../../../domain/modules/standardProfile/entity/StandardProfile";
import { StandardProfileData } from "../../../dataSource/standardProfileDataSource/IStandardProfileDataSource";
import IMapper from "../../../IMapper";

export default class StandardProfileMapper implements IMapper<StandardProfileData, StandardProfile> {
    map(input: StandardProfileData): StandardProfile {
        return new StandardProfile({
            name: NotEmptyString.create(input.name),
            description: input.description,
            siotRemoteSource: StringId.create(input.siotRemoteSource)
        }, NumericId.create(input.id))
    }
}