import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import {
  ChangeSignInInputs,
  SignInAction,
} from "../../redux/signIn/SignInActions";
import { RootStore } from "../../redux/Store";
import { Alert } from "@material-ui/lab";
import Loader from "../../components/Loader";
import { Icon } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Made with ♥ by "}
      <Link color="inherit" href="https://www.appmodule.net/">
        AppModule
      </Link>{" "}
      {"2021"}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  imageIcon: {
    height: '100%'
  },
  txtField: {
    marginBottom: '1.5vh',
    marginTop: '1vh'
  }
}));

export default function SignIn() {
  const state = useSelector((state: RootStore) => state.signInReducer);
  const dispatch = useDispatch();

  function onChangeInputs(field: string, value: string) {
    dispatch(ChangeSignInInputs({ field, value }));
  }

  function onSignIn(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch(SignInAction());
  }
  const classes = useStyles();

  const viewToRender = (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Icon className={classes.avatar}>
        <img className={classes.imageIcon}  src="/aliunid-flower.svg" alt="aliunid-logo" />
        </Icon>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSignIn}>
          {state.error && <Alert severity="error">{state.error}</Alert>}
          <TextField
            required
            id="email"
            variant="outlined"
            label="Email Address"
            name="email"
            className={classes.txtField}
            autoComplete="email"
            autoFocus
            value={state.email}
            onChange={(event) => onChangeInputs("email", event.target.value)}
          />
          <TextField
            required
            variant="outlined"
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={(event) => onChangeInputs("password", event.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )

  return state.isLoading ? <Loader /> : viewToRender;
}
