import IGetMappingsGateway from "../../../../domain/modules/mapping/gateway/IGetMappingsGateway";
import MappingList from "../../../../domain/modules/mapping/valueObject/MappingList";
import StandardProfileList from "../../../../domain/modules/standardProfile/valueObject/StandardProfileList";
import IStandardProfileRepository from "../../standardProfileRepository/IStandardProfileRepository";
import IMappingRepository from "../IMappingRepository";

export class GetMappingsGatewayError extends Error {
    constructor(message: string) {
        super(`[GetMappingsGateway] Error - ${message}`)
    }
}

export default class GetMappingsGateway implements IGetMappingsGateway {
    constructor(
        private _standardProfileRepository: IStandardProfileRepository,
        private mappingRepository: IMappingRepository
    ) { }

    public async getStandardProfiles(): Promise<StandardProfileList> {
        try {
            return await this._standardProfileRepository.getStandardProfiles();
        } catch (e) {
            throw new GetMappingsGatewayError(`[getStandardProfiles] - ${e.message}`)
        }
    }

    public async getMappings(): Promise<MappingList> {
        try {
            return await this.mappingRepository.getMappings();
        } catch (e) {
            throw new GetMappingsGatewayError(`[getMappings] - ${e.message}`)
        }
    }
}