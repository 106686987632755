import ISignOutGateway from "../gateway/ISignOutGateway"

export class SignOutError extends Error {
    constructor(message: string) {
        super(`[SignOutInteractor] Error - ${message}`)
    }
}

export interface ISignOutInput {
    signOut(): Promise<void>
}

export interface ISignOutOutput {
    displaySuccessResponse(): void;
    displayErrorResponse(error: Error): void;
}

export class SignOutInteractor implements ISignOutInput {

    constructor(
        private _output: ISignOutOutput,
        private _gateway: ISignOutGateway
    ) { }

    async signOut(): Promise<void> {
        try {
            await this.interact()
        } catch (error) {
            this._output.displayErrorResponse(new SignOutError(error.message))
        }
    }

    private async interact() {
        await this._gateway.signOut(this._gateway.getStoredToken())
        this._gateway.clearToken()
        this._output.displaySuccessResponse()
    }
}