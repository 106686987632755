import NumericId from "../../../domain/base/valueObject/uniqueEntityID/NumericId";
import { DELETE_MAPPING } from "../../redux/mappingList/MappingListActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import MappingErrors from "./MappingErrors";

export default class DeleteMappingPresenter extends ReduxPresenter implements IBasicOutput<NumericId> {
    constructor(dispatch: IReduxDispatch) {
        super(dispatch, new MappingErrors().processor);
    }

    displaySuccessResponse(id: NumericId): void {
        this.dispatch({
            type: DELETE_MAPPING,
            payload: {
                id: id.getId()
            }
        })
    }
}