import ReduxPresenter from "../ReduxPresenter";
import MappingViewModelPresentation from "./presentation/MappingViewModelPresentation";
import { IReduxDispatch } from "../IReduxDispatch";
import GetMappingsError from "./MappingErrors";
import MappingList from "../../../domain/modules/mapping/valueObject/MappingList";
import StandardProfileList from "../../../domain/modules/standardProfile/valueObject/StandardProfileList";
import { IGetMappingsOutput } from "../../../domain/modules/mapping/useCase/GetMappingsUseCase";
import { GET_MAPPINGS_SUCCESS } from "../../redux/mappingList/MappingListActionTypes";

export default class GetMappingsPresenter extends ReduxPresenter implements IGetMappingsOutput {
    constructor(dispatch: IReduxDispatch, private presentation: MappingViewModelPresentation) {
        super(dispatch, new GetMappingsError().processor);
    }

    displaySuccessResponse = (mappingList: MappingList, standardProfilesList: StandardProfileList): void => {
        const mappingViewModelList = this.presentation.present(mappingList, standardProfilesList)

        this.dispatch({
            type: GET_MAPPINGS_SUCCESS,
            payload: { mappingList: mappingViewModelList }
        })
    }

}