import { Grid, makeStyles, Theme, Container, Button, Divider } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../redux/Store";
import { SetLogoFile, UpdateLogoValue } from "../../../../redux/utility/UtilityActions";

interface UtilityFormDragDropProps {
    handleCloseEditLogo: () => void;
    set?: Dispatch<SetStateAction<string>>;
    edit: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        position: "relative",
        width: "100%",
        height: "100%"
    },
    container: {
        width: "100%",
        height: "90%",
        position: "absolute",
        padding: theme.spacing(1),
        overflowX: "hidden"
    },
    buttons: {
        width: "100%",
        height: "20%",
        position: "absolute",
        left: 0,
        top: "90%"
    },
    buttonGrid: {
        margin: theme.spacing(0, 0, 0),
    },
    contentContainer: {
        position: "relative",
        height: "100%",
        width: "100%"
    },
    dragDropCustom: {
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    hiddenContent: {
        display: "none"
    },
    imageContainer: {
        margin: theme.spacing(1, 0, 0),
        width: "100%",
        paddingBottom: "100%",
        position: "relative",
        borderRadius: "2%",
        border: `0.01px solid ${theme.palette.text.primary}`,
        backgroundColor: theme.palette.text.primary,
        overflow: "hidden"
    },
    image: {
        margin: "auto",
        top: "50%",
        left: "50%",
        width: "100%",
        position: "absolute",
        background: theme.palette.grey[300],
        backfaceVisibility: "hidden",
        display: "block",
        transform: "translate(-50%, -50%)"
    },
    discard: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.main,
        }
    },
    update: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        }
    }
}));

export default function UtilityFormDragDrop(props: UtilityFormDragDropProps) {
    const classes = useStyles();
    const [imageChanged, setImageChanged] = useState(false);
    const [uploadedFilePath, setUploadedFilePath] = useState("");
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const { selectedLogoUrl } = useSelector((state: RootStore) => state.utilityReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (uploadedFilePath !== "" && uploadedFilePath !== selectedLogoUrl) {
            setImageChanged(true);
        }
    }, [uploadedFilePath, selectedLogoUrl])

    const handleDropzone = (files: File[]) => {
        if (files.length > 0) {
            setUploadedFile(files[0]);
            setUploadedFilePath(URL.createObjectURL(files[0]));
        }
    }

    const handleGoBack = () => {
        setImageChanged(false);
    }

    const handleUpload = () => {
        if (uploadedFile !== null) {
            dispatch(SetLogoFile(uploadedFile));
            dispatch(UpdateLogoValue(uploadedFilePath));
            setImageChanged(false);
            props.edit(true);
            props.handleCloseEditLogo();
        }
    }

    return (
        <Container
            maxWidth={false}
            className={classes.root}
            disableGutters={true}
        >
            <Container
                maxWidth={false}
                className={classes.container}
                disableGutters={true}
            >
                <Container maxWidth={false} disableGutters={true} className={classes.contentContainer}>
                    <Container maxWidth={false} disableGutters={true}>
                        <DropzoneArea
                            acceptedFiles={['image/*']}
                            classes={{
                                root: imageChanged ? classes.hiddenContent : classes.dragDropCustom
                            }}
                            dropzoneText={"Click here or drag and drop an image"}
                            onChange={handleDropzone}
                            filesLimit={1}
                            showAlerts={false}
                            showPreviewsInDropzone={false}
                        />
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            className={!imageChanged ? classes.hiddenContent : classes.contentContainer}
                        >
                            <Grid item xs={12} sm={7} md={5} lg={4} xl={4}>
                                <Grid item xs={12} md={12} className={classes.imageContainer}>
                                    <img className={classes.image} alt={uploadedFilePath} src={uploadedFilePath} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="baseline"
                                    alignContent="center"
                                    spacing={1}
                                    className={classes.buttonGrid}
                                >
                                    <Grid item xs={2} md={4} />
                                    <Grid item xs={8} md={4}>
                                        <Grid
                                            container
                                        >
                                            <Grid item xs={2} md={2}>
                                            </Grid>
                                            <Grid item xs={8} md={8}>
                                                <Button
                                                    className={classes.discard}
                                                    type="button"
                                                    size="large"
                                                    fullWidth
                                                    onClick={handleGoBack}
                                                >
                                                    Discard
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} md={4} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Container>
            <Container
                maxWidth={false}
                className={classes.buttons}
                disableGutters={true}
            >
                <Divider variant="middle" />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="baseline"
                    alignContent="center"
                    spacing={1}
                    className={classes.buttonGrid}
                >
                    <Grid item xs={2} md={4} />
                    <Grid item xs={8} md={4}>
                        <Grid
                            container
                        >
                            <Grid item xs={5} md={5}>
                                <Button
                                    type="button"
                                    size="large"
                                    fullWidth
                                    onClick={props.handleCloseEditLogo}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={2} md={2}></Grid>
                            <Grid item xs={5} md={5}>
                                <Button
                                    className={classes.update}
                                    type="submit"
                                    size="large"
                                    disabled={!imageChanged}
                                    fullWidth
                                    onClick={handleUpload}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} md={4} />
                </Grid>
            </Container>
        </Container>
    )
}