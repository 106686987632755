import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import StandardProfileTable from "../../../components/table/StandardProfileTable";
import { StandardProfileViewModel } from "../../../presenter/standardProfile/viewModel/StandardProfileViewModel";
import {
  GetStandardProfilesAction,
  EditStandardProfile,
} from "../../../redux/standardProfileList/StandardProfileListActions";
import { RootStore } from "../../../redux/Store";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(-3),
  },
}));

export default function Profiles() {
  const { profilesList, error, loading } = useSelector(
    (state: RootStore) => state.standardProfileListReducer
  );

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetStandardProfilesAction());
  }, [dispatch]);

  const handleEdit = (
    newData: StandardProfileViewModel,
    oldData: StandardProfileViewModel | undefined
  ) => {
    dispatch(EditStandardProfile(newData));
    return Promise.resolve();
  };

  const viewToRender = (
    <div className={classes.main}>
      <StandardProfileTable profileList={profilesList} handleEdit={handleEdit} />
    </div>
  );

  if (!error && !loading) {
    return viewToRender;
  } else if (loading) {
    return <Loader />;
  } else {
    return <ErrorMessage message={error} />;
  }
}
