export const SIGN_IN_LOADING = "SIGN_IN_LOADING";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_INPUTS = "SIGN_IN_INPUTS";

export type SignInInputsModel = {
    field: string;
    value: string;
}

interface SignInLoading {
    type: typeof SIGN_IN_LOADING
}

interface SignInFail {
    type: typeof SIGN_IN_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface SignInSuccess {
    type: typeof SIGN_IN_SUCCESS
}

interface SignInInputs {
    type: typeof SIGN_IN_INPUTS,
    payload: SignInInputsModel;
}

export type SignInActionTypes = SignInLoading | SignInSuccess | SignInFail | SignInInputs;