import { MappingViewModel } from "../../presenter/mapping/viewModel/MappingViewModel";

export const GET_MAPPINGS_FAIL = "GET_MAPPINGS_FAIL";
export const GET_MAPPINGS_LOADING = "GET_MAPPINGS_LOADING";
export const GET_MAPPINGS_SUCCESS = "GET_MAPPINGS_SUCCESS";
export const UPDATE_MAPPING = "UPDATE_MAPPING";
export const UPDATE_MAPPING_FAIL = "UPDATE_MAPPING_FAIL";
export const ADD_MAPPING = "ADD_MAPPING";
export const ADD_MAPPING_FAIL = "ADD_MAPPING_FAIL";
export const DELETE_MAPPING = "DELETE_MAPPING";
export const DELETE_MAPPING_FAIL = "DELETE_MAPPING_FAIL";
export const RECALCULATE_STANDARD_PROFILES_START = "RECALCULATE_STANDARD_PROFILES_START";
export const RECALCULATE_STANDARD_PROFILES_FAIL = "RECALCULATE_STANDARD_PROFILES_FAIL";

interface GetMappingsLoading {
    type: typeof GET_MAPPINGS_LOADING;
}

interface GetMappingsFail {
    type: typeof GET_MAPPINGS_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface GetMappingsSuccess {
    type: typeof GET_MAPPINGS_SUCCESS,
    payload: {
        mappingList: MappingViewModel[]
    };
}

interface UpdateMapping {
    type: typeof UPDATE_MAPPING,
    payload: {
        mapping: MappingViewModel
    }
}

interface UpdateMappingFail {
    type: typeof UPDATE_MAPPING_FAIL;
    payload: {
        errorMessage: string;
    };
}

interface AddMapping {
    type: typeof ADD_MAPPING,
    payload: {
        mapping: MappingViewModel
    }
}

interface AddMappingFail {
    type: typeof ADD_MAPPING_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface DeleteMapping {
    type: typeof DELETE_MAPPING,
    payload: {
        id: number;
    }
}

interface DeleteMappingFail {
    type: typeof DELETE_MAPPING_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface RecalculateStandardProfilesFail {
    type: typeof RECALCULATE_STANDARD_PROFILES_FAIL,
    payload: {
        errorMessage: string;
    };
}

export type GetMappingsActionTypes = GetMappingsLoading | GetMappingsFail | GetMappingsSuccess | UpdateMappingFail |
    UpdateMapping | AddMapping | AddMappingFail | DeleteMapping | DeleteMappingFail | RecalculateStandardProfilesFail;