import Utility from "../../../domain/modules/utility/entity/Utility";
import UtilityList from "../../../domain/modules/utility/valueObject/UtilityList";
import UtilityLogo from "../../../domain/modules/utility/valueObject/UtilityLogo";
import IUtilityDataSource from "../../dataSource/utilityDataSource/IUtilityDataSource";
import IUtilityRepositoryMapperFactory from "./factory/IUtilityRepositoryMapperFactory";
import { IUtilityRepository } from "./IUtilityRepository";

export class UtilityRepositoryError extends Error {
    constructor(message: string) {
        super(`[UtilityRepository] Error - ${message}`);
    }
}

export class UtilityRepository implements IUtilityRepository {

    constructor(private _dataSource: IUtilityDataSource, private _mapperFactory: IUtilityRepositoryMapperFactory) { }

    async getUtilityList(): Promise<UtilityList> {
        try {
            const utilities = await this._dataSource.getUtilityList();
            const utilitiesMapped = utilities.map(utility => this._mapperFactory.getUtilityMapper().map(utility));

            return UtilityList.create(utilitiesMapped)
        } catch (err) {
            throw new UtilityRepositoryError(`[getUtilityList] - ${err.message}`);
        }
    }

    async getUtility(id: number): Promise<Utility> {
        try {
            const utility = await this._dataSource.getUtility(id);
            const utilityMapped = this._mapperFactory.getUtilityMapper().map(utility);

            return utilityMapped
        } catch (err) {
            throw new UtilityRepositoryError(`[getUtility] - ${err.message}`);
        }
    }

    async editUtility(utility: Utility): Promise<Utility> {
        try {
            const utilityId = utility.id.getId();
            const utilityEditData = this._mapperFactory.getUtilityEditDataMapper().map(utility);

            await this._dataSource.editUtility(utilityId, utilityEditData);
            return utility
        } catch (err) {
            throw new UtilityRepositoryError(`[editUtility] - ${err.message}`);
        }
    }

    async uploadLogo(logo: File): Promise<UtilityLogo> {
        try {
            const uploadedLogo = await this._dataSource.uploadLogo(logo);
            const logoMapped = this._mapperFactory.getUtilityLogosMapper().map(uploadedLogo);

            return logoMapped
        } catch (err) {
            throw new UtilityRepositoryError(`[editUtility] - ${err.message}`);
        }
    }

    async getUtilityLogos(): Promise<UtilityLogo[]> {
        try {
            const logosData = await this._dataSource.getUtilityLogos();
            const logos = logosData.map(logo => this._mapperFactory.getUtilityLogosMapper().map(logo));

            return logos
        } catch (err) {
            throw new UtilityRepositoryError(`[getUtilityLogos] - ${err.message}`);
        }
    }
}