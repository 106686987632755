import { ValueObject } from "../../../base/valueObject/ValueObject";

export class ContactsError extends Error {
    constructor(message: string) {
        super(`[Contacts] Error - ${message}`);
    }
}

interface ContactsProps {
    address: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    website: string | undefined;
}

export default class Contacts extends ValueObject<ContactsProps> {

    get address(): string | undefined {
        return this.props.address;
    }

    get email(): string | undefined {
        return this.props.email;
    }

    get phone(): string | undefined {
        return this.props.phone;
    }

    get website(): string | undefined {
        return this.props.website;
    }

    private constructor(props: ContactsProps) {
        super(props);
    }

    public static create(address: string | undefined, email: string | undefined, phone: string | undefined, website: string | undefined): Contacts {
        if (!Contacts.validate(address))
            throw new ContactsError(`Address ${address} field is not string.`)
        if (!Contacts.validate(email))
            throw new ContactsError(`Email ${email} field is not string.`)
        if (!Contacts.validate(phone))
            throw new ContactsError(`Phone number ${phone} field is not string.`)
        if (!Contacts.validate(website))
            throw new ContactsError(`Website ${website} field is not string.`)
        return new Contacts({ address, email, phone, website })
    }

    private static validate(value: string | undefined): boolean {
        return typeof (value) === "string" || value === undefined
    }

    public equals(vo: ValueObject<ContactsProps>): boolean {
        return vo.props.address === this.address && vo.props.email === this.email
            && vo.props.phone === this.phone && vo.props.website === this.website;
    }
}