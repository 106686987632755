import { IGetUserOutput } from "../../../domain/modules/user/useCase/GetUserUseCase";
import ReduxPresenter from "../ReduxPresenter";
import UserErrors from "./UserErrors";
import UserPresentation from "./presentation/UserPresentation";
import { IReduxDispatch } from "../IReduxDispatch";
import User from "../../../domain/modules/user/entity/User";
import { GET_USER_SUCCESS } from "../../redux/user/UserActionTypes";

export default class GetUserPresenter extends ReduxPresenter implements IGetUserOutput {

    constructor(dispatch: IReduxDispatch, private presentation: UserPresentation) {
        super(dispatch, new UserErrors().processor);
    }

    displaySuccessResponse = (user: User): void => {
        const userViewModel = this.presentation.map(user)

        this.dispatch({
            type: GET_USER_SUCCESS,
            payload: { 
                user: userViewModel
            }
        })
    }

}