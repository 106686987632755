import Token from "../../../../domain/modules/auth/valueObject/Token";
import { TokenData } from "../../../dataSource/tokenDataSource/ITokenDataSource";
import IMapper from "../../../IMapper";

export default class TokenDataMapper implements IMapper<Token, TokenData> {
    map(input: Token): TokenData {
        return {
            accessToken: input.accessToken.value,
            refreshToken: input.refreshToken.value
        }
    }
}