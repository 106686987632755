import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import Entity from "../../../base/entity/Entity";
import Logos from "../valueObject/Logos";
import Contacts from "../valueObject/Contacts";
import SocialNetworks from "../valueObject/SocialNetworks";
import Description from "../valueObject/Description";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";

export class UtilityError extends Error {
    constructor(message: string) {
        super(`[Utility] Error - ${message}`)
    }
}

interface UtilityProps {
    name: NotEmptyString;
    shortName: NotEmptyString;
    description?: Description;
    logos?: Logos;
    contacts?: Contacts;
    socialNetworks?: SocialNetworks;
}

export default class Utility extends Entity<UtilityProps, number> {

    get id(): NumericId {
        return NumericId.create(this._id.getId());
    }

    get name(): NotEmptyString {
        return this.props.name
    }

    get shortName(): NotEmptyString {
        return this.props.shortName
    }

    get description(): Description | undefined {
        return this.props.description
    }

    get logos(): Logos | undefined {
        return this.props.logos
    }

    get contacts(): Contacts | undefined {
        return this.props.contacts
    }

    get socialNetworks(): SocialNetworks | undefined {
        return this.props.socialNetworks
    }
}