import { Container, Grid, makeStyles } from "@material-ui/core";
import { SetStateAction } from "react";
import { Dispatch } from "react";

interface LogoElementProps {
    logo: string;
    selected: number;
    setSelected: Dispatch<SetStateAction<number>>;
    index: number;
    updated: boolean;
    setUpdated: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        height: "100%",
        paddingBottom: "100%",
        position: "relative",
        borderRadius: "2%",
        border: `0.1px solid ${theme.palette.text.primary}`,
        opacity: 1,
        overflow: "hidden",
        backgroundColor: theme.palette.text.primary,
        "&:hover": {
            cursor: "pointer",
            "& $image": {
                opacity: 0.9
            }
        }
    },
    imageContainerActive: {
        height: "100%",
        paddingBottom: "100%",
        position: "relative",
        borderRadius: "2%",
        border: `0.1px solid ${theme.palette.primary.main}`,
        overflow: "hidden",
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        "& $image": {
            opacity: 0.9
        },
        "&:hover": {
            cursor: "pointer",
            "& $image": {
                opacity: 0.9
            }
        }
    },
    image: {
        margin: "auto",
        top: "50%",
        left: "50%",
        width: "100%",
        position: "absolute",
        background: theme.palette.grey[300],
        backgroundSize: "auto",
        transition: ".5s ease",
        opacity: 1,
        backfaceVisibility: "hidden",
        display: "block",
        transform: "translate(-50%, -50%)"
    }
}));

export default function LogoElement(props: LogoElementProps) {
    const { logo, selected, index, updated } = props;
    const classes = useStyles();

    const handleSelected = () => {
        if (!updated) props.setUpdated(true);
        props.setSelected(index);
    }

    return (
        <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <Container
                maxWidth={false}
                disableGutters={true}
                className={selected === index ? classes.imageContainerActive : classes.imageContainer}
                onClick={handleSelected}
            >
                <img src={logo} className={classes.image} alt={logo}></img>
            </Container>
        </Grid>
    );
}
