import StringId from "../../../domain/base/valueObject/uniqueEntityID/StringId";
import User from "../../../domain/modules/user/entity/User";
import UserList from "../../../domain/modules/user/valueObject/UserList";
import IUserDataSource from "../../dataSource/userDataSource/IUserDataSource";
import IUserRepositoryMapperFactory from "./factory/IUserRepositoryMapperFactory";
import IUserRepository from "./IUserRepository";


export class UserRepositoryError extends Error {
    constructor(message: string) {
        super(`[UserRepository] Error - ${message}`)
    }
}

export default class UserRepository implements IUserRepository {
    constructor(
        private _dataSource: IUserDataSource,
        private _mapperFactory: IUserRepositoryMapperFactory
    ) { }

    async getUsers(): Promise<UserList> {
        try {
            const users = await this._dataSource.getUsers()
            const usersMap = users.map(userData => this._mapperFactory.getUserMapper().map(userData))
            return UserList.create(usersMap)

        } catch (error) {
            throw new UserRepositoryError(`[getUsers] - ${error.message}`)
        }
    }

    async getUser(id: StringId): Promise<User> {
        try {
            const user = await this._dataSource.getUser(id.getId())
            const userMap = this._mapperFactory.getUserMapper().map(user)
            return userMap
        } catch (error) {
            throw new UserRepositoryError(`[getUser] - ${error.message}`)
        }
    }

    async editUser(id: StringId, user: User): Promise<User> {
        try {
            await this._dataSource.editUser(id.getId(), this._mapperFactory.getUserDataMapper().map(user))
            return user
        } catch (err) {
            throw new UserRepositoryError(`[editUser] - ${err.message}`)
        }
    }

    findUserById(id: StringId, userList: UserList): User {
        try {
            return userList.users.filter(u => u.id.equals(id))[0]
        } catch (err) {
            throw new UserRepositoryError(`[findUserById] -  ${err.message}`)
        }
    }
}